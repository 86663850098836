import { useState } from "react";

import ExteriorImg from "../../assets/imgs/mfg/tall-enclosure.png";

import plusIcon from "../../assets/imgs/icons/plus-icon.png";
import dataArray from "./ExteriorData";

import InteractableModal from "./InteractableModal";

import "./new-interactable.css";

const ExteriorInteractable = () => {
  const [showDescription, setShowDescription] = useState(false);
  const [data, setData] = useState();
  const buttonArray = [
    "exterior-top",
    "exterior-middle",
    "exterior-right",
    "exterior-bottom",
  ];
  const handleButtonClick = (key) => {
    setShowDescription(true);
    setData(dataArray.dataArray[key]);
    console.log(dataArray.dataArray[key]);
  };
  return (
    <div className="interactable-img-container">
      <div
        style={{
          width: "fit-content",
          height: "fit-content",
          margin: "auto",
          position: "relative",
        }}
      >
        <img
          className="interactable-main-img"
          src={ExteriorImg}
          alt="enclosure demo"
        />
        {buttonArray.map((item, key) => {
          return (
            <div
              key={key}
              className={`${item} interactable-plus-icon `}
              onClick={() => handleButtonClick(key)}
            >
              <img src={plusIcon} alt="plus icon" />
            </div>
          );
        })}
      </div>
      {showDescription && (
        <InteractableModal
          data={data}
          setShowDescription={setShowDescription}
        />
      )}
    </div>
  );
};

export default ExteriorInteractable;
