import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
import EnclosureShowcaseComponent from "../enclosure-showcase/EnclosureShowcaseComponent";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

const ShowcaseSlider = ({ data }) => {
  return (
    <div className="series-enclosure-showcase show-on-mobile">
      <Swiper
        id="carousel-two"
        slidesPerView={1}
        spaceBetween={10}
        centeredSlides
        loop={true}
        autoplay={{
          delay: 7000,
          disableOnInteraction: false,
        }}
        speed={1500}
        modules={[Autoplay, Pagination, Navigation]}
      >
        {data &&
          data.map((enc, key) => {
            return (
              <SwiperSlide key={key} id="showcase-swiper">
                <EnclosureShowcaseComponent
                  key={key}
                  index={key}
                  num={data.length}
                  enclosure={enc}
                />
              </SwiperSlide>
            );
          })}
      </Swiper>
    </div>
  );
};

export default ShowcaseSlider;
