import React from "react";
import pdfSvg from "../../assets/imgs/icons/PDF_icon.svg";

import "../../assets/styles/seriesPage.css";

const EnclosureShowcaseComponent = ({ enclosure, index, num }) => {
  return (
    <div className="enclosure-showcase-component-container">
      <div
        className="flex-center-center flex-column"
        style={{ minHeight: "300px", marginBottom: "40px" }}
      >
        <img
          className="enclosure-showcase-preview margin-auto"
          src={enclosure.img}
          alt="enclosure preview"
        />
        <h5 style={{ fontSize: "28px", fontWeight: "500" }}>
          {enclosure.partNum}
        </h5>
      </div>
      <div className="flex" style={{ height: "60%" }}>
        <div className="showcase-data-container">
          <h4 style={{ fontSize: "18px" }}>
            {enclosure.showcaseTitle && enclosure.showcaseTitle}
          </h4>
          <div>
            <h4>Technical Specs</h4>
            <ul style={{ listStyle: "none" }}>
              {enclosure.technicalSpecs &&
                enclosure.technicalSpecs.map((tech, key) => {
                  return (
                    <li style={{ margin: "5px 0" }} key={key}>
                      {tech}
                    </li>
                  );
                })}
            </ul>
          </div>
          <div>
            <h4>Key Features</h4>
            <p style={{ fontSize: "16px" }}>
              {enclosure.keyFeatures && enclosure.keyFeatures}
            </p>
          </div>
          <div className="single-product-resources" style={{ margin: "0" }}>
            <h4 style={{ color: "#000", margin: "5px 0" }}>Resources</h4>
            <div>
              <div>
                <img
                  src={pdfSvg}
                  alt="pdf download"
                  style={{
                    filter:
                      "invert(56%) sepia(62%) saturate(5971%) hue-rotate(348deg) brightness(93%) contrast(84%)",
                    height: "16px",
                    margin: "auto 0",
                  }}
                />
                <a
                  style={{
                    color: "var(--brownish-orange)",
                    marginLeft: "5px",
                  }}
                  href={(enclosure && enclosure.pdf) || ""}
                  target={enclosure && enclosure.pdf && "_blank"}
                  rel="noreferrer"
                >
                  Specification Sheet
                </a>
              </div>
              <div>
                <img
                  src={pdfSvg}
                  alt="pdf download"
                  style={{
                    filter:
                      "invert(56%) sepia(62%) saturate(5971%) hue-rotate(348deg) brightness(93%) contrast(84%)",
                    height: "16px",
                    margin: "auto 0",
                  }}
                />
                {enclosure && enclosure.installGuide && (
                  <a
                    style={{
                      color: "var(--brownish-orange)",
                      marginLeft: "5px",
                    }}
                    href={(enclosure && enclosure.installGuide) || ""}
                    target={enclosure && enclosure.installGuide && "_blank"}
                    rel="noreferrer"
                  >
                    Installation Guide
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
        {index < num - 1 && (
          <hr className="enclosure-showcase-divider hide-hr-mobile" />
        )}
      </div>
    </div>
  );
};

export default EnclosureShowcaseComponent;
