import React from "react";

import ManufacturingCard from "../../components/manufacturing-card/ManufacturingCard";
import ContactComponent from "../../components/contact/ContactComponent";
import LinkComponent from "../../components/link-section/LinkComponent";

import HeroImg from "../../assets/imgs/hero-imgs/IMG_0807.png";
import CardOne from "../../assets/imgs/card-images/metal-bending-1.png";
import CardTwo from "../../assets/imgs/card-images/metal-bending-2.png";
import MetalBendingThumbnail from "../../assets/imgs/thumbnails/metal-bending-thumbnail.png";

import VideoComponent from "../../components/video-component/VideoComponent";

import "../../assets/styles/template.css";
import "../../assets/styles/template-mobile.css";

const MetalBendingPage = () => {
  const cardData = [
    {
      img: CardOne,
      title: "Expertise",
      text: "Our team of skilled technicians and engineers use computer-aided design (CAD) software to create 3D models of the parts, which are then used to program the CNC machines to ensure accuracy and consistency in the forming process.",
      orientation: false,
    },
    {
      img: CardTwo,
      title: "Materials",
      text: "We work with a wide range of materials, including stainless steel, aluminum, copper, and brass, and offer a variety of finishes to meet your desired aesthetic and functional requirements.",
      orientation: true,
    },
  ];

  const infoData = {
    text: null,
    video: "https://www.youtube.com/embed/9UXcc2Nm3BY",
    thumbnail: MetalBendingThumbnail,
  };

  return (
    <div className="page-container">
      <section
        className="hero-section"
        style={{ backgroundImage: `url(${HeroImg})` }}
      >
        <div className="hero-shader"></div>
        <div className="hero-header">
          <h1>SHEET METAL BENDING</h1>
          <h4>Raw sheet metal into precision parts</h4>
        </div>
      </section>
      <section className="intro-section">
        <p>
          Our sheet metal forming services utilize state-of-the-art technology
          to create custom parts and components to meet your specific needs. We
          use a variety of processes, including bending, stamping, and deep
          drawing, to transform raw sheet metal into precision parts with the
          desired shape and size.
        </p>
      </section>
      <section className="card-container">
        <h2>HOW WE'RE DIFFERENT</h2>
        {cardData.map((card, key) => {
          return (
            <ManufacturingCard
              key={key}
              img={card.img}
              title={card.title}
              text={card.text}
              orientation={card.orientation}
            />
          );
        })}
      </section>
      <section className="info-section">
        <VideoComponent data={infoData} />
      </section>
      <section>
        <LinkComponent />
      </section>
      <section>
        <ContactComponent />
      </section>
    </div>
  );
};

export default MetalBendingPage;
