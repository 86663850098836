import React, { useState } from "react";
import { Link } from "react-router-dom";
import VideoSection from "./VideoSection";
import VideoModal from "./VideoModal";

import "./collection.css";

const Collection = () => {
  const [isVideoModal, setIsVideoModal] = useState(false);
  const [videoPlaying, setVideoPlaying] = useState();

  const videoFunc = (video) => {
    setVideoPlaying(video);
  };

  return (
    <div id="collection">
      <VideoSection videoFunc={videoFunc} setIsVideoModal={setIsVideoModal} />
      {isVideoModal && (
        <VideoModal
          videoPlaying={videoPlaying}
          setIsVideoModal={setIsVideoModal}
          setVideoPlaying={setVideoPlaying}
        />
      )}
    </div>
  );
};

export default Collection;
