import { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";

import EnclosureShowcaseComponent from "../../components/enclosure-showcase/EnclosureShowcaseComponent";
import ContactComponent from "../../components/contact/ContactComponent";
import ShowcaseSlider from "../../components/showcaseSlider/ShowcaseSlider";
import EnclosureData from "../../util/data/all-products/EnclosureData";

import pdfSvg from "../../assets/imgs/icons/PDF_icon.svg";

import "../../assets/styles/seriesPage.css";
import "../../assets/styles/singleProductPage.css";

const SeriesPage = () => {
  const location = useLocation();
  const [enclosure, setEnclosure] = useState();
  const [seriesData, setSeriesData] = useState();
  const [mainImg, setMainImg] = useState("enc 1");
  const [selectedButton, setSelectedButton] = useState(0);
  const [selectedImg, setSelectedImg] = useState(0);
  let { selectedProduct, index } = useParams();

  useEffect(() => {
    if (location.state) {
      setEnclosure(location.state.encArr[0]);
      setSeriesData(location.state);
      location.state && setMainImg(location.state.img);
      console.log(enclosure);
    } else {
      if (selectedProduct === "Enclosures") {
        let encData = EnclosureData[index];
        console.log(encData);
        setEnclosure(EnclosureData[index].encArr[0]);
        setSeriesData(EnclosureData[index]);
      }
    }
  }, [location]);

  useEffect(() => {
    enclosure && setMainImg(enclosure.img);
  }, [enclosure]);

  const handleImgChange = (img, key) => {
    setMainImg(img);
    setSelectedImg(key);
  };

  const changeEnclosure = (enc, key) => {
    setSelectedButton(key);
    setEnclosure(enc);
    setSelectedImg(0);
  };

  return (
    <>
      <div className="series-page">
        <div className="breadcrumb-container">
          <ul>
            <Link to="/products">
              <li>Products</li>
            </Link>
            <p>{`/`}</p>
            <Link to="/products">
              <li style={{ color: "var(--brownish-orange)" }}>
                {sessionStorage.getItem("selectedProduct")
                  ? sessionStorage.getItem("selectedProduct")
                  : "Enclosures"}
              </li>
            </Link>
            <p
              style={{ color: "var(--brownish-grey)", opacity: ".8" }}
            >{`/`}</p>
            <li style={{ opacity: ".8" }}>{seriesData && seriesData.title}</li>
          </ul>
        </div>
        <div>
          <div className="flex-center width-100 padding-50-0 series-page-header">
            <div className="width-50 text-start height-100 padding-25">
              <h2 style={{ color: "var(--brownish-orange)" }}>
                {seriesData && seriesData.header}
              </h2>
              <p>{seriesData && seriesData.headerInfo}</p>
            </div>
            <div className="width-50">
              <img
                className="width-100 padding-25"
                src={seriesData && seriesData.headerImage}
                alt=""
              />
            </div>
          </div>
          <hr className="series-page-hr" />
        </div>
        <div className="single-product-page" style={{ paddingBottom: "40px" }}>
          <div
            className="single-product-main-content"
            style={{ position: "relative" }}
          >
            <div className="single-product-img-container">
              <div>
                <div className="single-product-main-img">
                  <img className="main-img" src={mainImg} alt={mainImg} />
                </div>
                <div className="single-product-other-imgs">
                  {enclosure &&
                    enclosure.imgArr &&
                    enclosure.imgArr.map((img, key) => {
                      return (
                        <div
                          key={key}
                          style={
                            key === selectedImg
                              ? { border: "1px solid var(--brownish-orange)" }
                              : {}
                          }
                          onClick={() => handleImgChange(img, key)}
                        >
                          <img src={img} alt={`enc ` + img} />
                        </div>
                      );
                    })}
                </div>
                <div className="single-product-resources">
                  <h3>Resources</h3>
                  <div>
                    <div>
                      <img
                        src={pdfSvg}
                        alt="pdf download"
                        style={{
                          filter:
                            "invert(56%) sepia(62%) saturate(5971%) hue-rotate(348deg) brightness(93%) contrast(84%)",
                          height: "16px",
                          margin: "auto 0",
                        }}
                      />
                      <a
                        style={{
                          color: "var(--brownish-orange)",
                          marginLeft: "5px",
                        }}
                        href={(enclosure && enclosure.pdf) || ""}
                        target={enclosure && enclosure.pdf && "_blank"}
                        rel="noreferrer"
                      >
                        Specification Sheet
                      </a>
                    </div>
                    <div>
                      <img
                        src={pdfSvg}
                        alt="pdf download"
                        style={{
                          filter:
                            "invert(56%) sepia(62%) saturate(5971%) hue-rotate(348deg) brightness(93%) contrast(84%)",
                          height: "16px",
                          margin: "auto 0",
                        }}
                      />
                      <a
                        style={{
                          color: "var(--brownish-orange)",
                          marginLeft: "5px",
                        }}
                        href={(enclosure && enclosure.installGuide) || ""}
                        target={enclosure && enclosure.installGuide && "_blank"}
                        rel="noreferrer"
                      >
                        Installation Guide
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="single-product-data-container">
              <div
                style={{
                  width: "100%",
                  maxWidth: "1250px",
                  marginTop: "0",
                }}
              >
                <div className="series-enclosure-btn-container">
                  <h3>Model</h3>
                  {seriesData &&
                    seriesData.encArr.map((enc, key) => {
                      return (
                        <button
                          style={
                            selectedButton === key
                              ? { border: "1px solid var(--brownish-orange)" }
                              : {}
                          }
                          onClick={() => changeEnclosure(enc, key)}
                          className="series-enclosure-select-btn"
                          key={key}
                        >
                          <h3 style={{ margin: "0" }}>{enc.partNum}</h3>
                        </button>
                      );
                    })}
                </div>
              </div>
              <h3>Overview</h3>
              <p>{enclosure && enclosure.description}</p>

              <div>
                {enclosure && <h3>Protect your Investments</h3>}
                {enclosure && <p>{enclosure.protect}</p>}
              </div>
              <div>
                {enclosure && enclosure.simplicity && (
                  <div>
                    <h3>Simplicity Maximized</h3>
                    <p>{enclosure.simplicity}</p>
                  </div>
                )}
              </div>
              <div className="tech-specs-list-container">
                {enclosure &&
                  enclosure.technicalSpecs &&
                  enclosure.technicalSpecs.length > 0 && (
                    <h3>Technical Specifications</h3>
                  )}
                <ul>
                  {enclosure &&
                    enclosure.technicalSpecs &&
                    enclosure.technicalSpecs.map((include, key) => {
                      return (
                        <li key={key}>
                          {include}
                          <hr />
                        </li>
                      );
                    })}
                </ul>
              </div>

              <div>
                {enclosure &&
                  enclosure.features &&
                  enclosure.features.length > 0 && <h3>Features</h3>}
                <ul className="series-page-ul-dots">
                  {enclosure &&
                    enclosure.features &&
                    enclosure.features.map((feature, key) => {
                      return <li key={key}>{feature}</li>;
                    })}
                </ul>
              </div>

              <div>
                {enclosure &&
                  enclosure.kitIncludes &&
                  enclosure.kitIncludes.length > 0 && <h3>Kit Includes</h3>}
                <ul className="series-page-ul-dots">
                  {enclosure &&
                    enclosure.kitIncludes &&
                    enclosure.kitIncludes.map((feature, key) => {
                      return <li key={key}>{feature}</li>;
                    })}
                </ul>
              </div>

              <div>
                {enclosure &&
                  enclosure.features &&
                  enclosure.features.length > 0 && <h3>Kit Options</h3>}
                <ul className="series-page-ul-dots">
                  {enclosure &&
                    enclosure.kitOptions &&
                    enclosure.kitOptions.map((option, key) => {
                      return <li key={key}>{option}</li>;
                    })}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="showcase-section">
          <h3 style={{ color: "#000", width: "100%", textAlign: "center" }}>
            Explore the {seriesData && seriesData.line}
          </h3>
          <p className="series-showcase-label">
            {seriesData && seriesData.encArr.length} models to choose from
          </p>
          {/* show case component */}
          <div className="series-enclosure-showcase hide-on-mobile">
            {seriesData &&
              seriesData.encArr.map((enc, key) => {
                return (
                  <EnclosureShowcaseComponent
                    key={key}
                    index={key}
                    num={seriesData.encArr.length}
                    enclosure={enc}
                  />
                );
              })}
          </div>
          <div className="mobile-showcase-slider">
            <ShowcaseSlider data={seriesData && seriesData.encArr} />
          </div>
        </div>
      </div>
      <ContactComponent />
    </>
  );
};

export default SeriesPage;
