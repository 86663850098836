import React from "react";
import ContactForm from "./ampthink-contact-components/ContactForm";
import PhoneIcon from "../../assets/imgs/icons/phone.png";
import MailIcon from "../../assets/imgs/icons/mail.png";
import LocationIcon from "../../assets/imgs/icons/location.png";

import "./contact.css";

const Contact = () => {
  return (
    <div id="contact" className="contact-container">
      <h2>Contact Us</h2>
      <div className="contact-content-container">
        <div className="contact-text-container">
          <div className="contact-form">
            <ContactForm />
          </div>
          {/* <p>
            If our off-the-shelf options don't quite fit your needs, don't
            hesitate to give us a call. Because we build everything in-house,
            including the molds used to form our enclosures, we can
            cost-effectively build our enclosures at small volumes. This means
            that we have the flexibility to meet your specific requirements and
            deliver customized solutions that meet your unique needs.
          </p>
          <p>As we like to say, "if you can imagine it, we can build it".</p> */}
        </div>
        <div>
          <a href="tel: 1.800.735.6310">
            <div className="contact-info">
              <img className="contact-icons" src={PhoneIcon} alt="phone icon" />
              <p>1.800.735.6310</p>
            </div>
          </a>
          <a href="mailto: sales@ampthink.com">
            <div className="contact-info">
              <img className="contact-icons" src={MailIcon} alt="mail icon" />
              <p>sales@ampthink.com</p>
            </div>
          </a>
          <a
            href="http://maps.google.com/maps?q=3827+Dividend+Dr,+Garland,+TX+75042"
            target="blank"
          >
            <div className="contact-info">
              <img
                className="contact-icons"
                src={LocationIcon}
                alt="location icon"
              />
              <p>3827 Dividend Dr. Garland, TX 75042</p>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Contact;
