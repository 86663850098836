import React from "react";

import "../../assets/styles/thermoforming-table.css";

const ThermoformingTable = () => {
  return (
    <div className="thermoforming-table-container">
      <div>
        <h2>THERMOFORMING VS INJECTION MOLDING</h2>
        <p className="max-width-900">
          The choice between thermoforming and injection molding depends on
          factors such as part complexity, volume requirements, design
          flexibility, cost considerations, and material selection.
          Thermoforming is often favored for low to medium volume production of
          simpler parts, while injection molding is preferred for high-volume
          production of simpler parts, while injection molding is preferred for
          high-volume production and complex parts with precise specifications.
        </p>
      </div>
      <div>
        <table>
          <thead>
            <tr>
              <th></th>
              <th className="background-orange td-padding">THERMOFORMING</th>
              <th className="background-black td-padding">INJECTION MOLDING</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="td-padding-small td-height">
                <h4>MATERIALS</h4>
              </td>
              <td className="background-white text-orange td-padding max-width-500 td-height">
                Flat extruded sheets in various types of thermoplastics
              </td>
              <td className="background-white td-padding max-width-500 td-height">
                Thermoplastic pellets in limited finishes
              </td>
            </tr>
            <tr>
              <td className="td-padding-small td-height">
                <h4>LEAD TIME</h4>
              </td>
              <td className="background-white text-orange td-padding max-width-500 td-height">
                6-8 weeks
              </td>
              <td className="background-white td-padding max-width-500 td-height">
                12-16 weeks
              </td>
            </tr>
            <tr>
              <td className="td-padding-small td-height">
                <h4>VOLUME PRODUCTION</h4>
              </td>
              <td className="background-white text-orange td-padding max-width-500 td-height">
                No minimum required
              </td>
              <td className="background-white td-padding max-width-500 td-height">
                Large volume requirement
              </td>
            </tr>
            <tr>
              <td className="td-padding-small td-height">
                <h4>TOOLING COST</h4>
              </td>
              <td className="background-white text-orange td-padding max-width-500 td-height">
                $$
              </td>
              <td className="background-white td-padding max-width-500 td-height">
                $$$$$
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ThermoformingTable;
