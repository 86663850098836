import React from "react";
import HeroImg from "../../assets/imgs/hero-imgs/IMG_0881.JPG";

import ManufacturingCard from "../../components/manufacturing-card/ManufacturingCard";
import VideoComponent from "../../components/video-component/VideoComponent";
import LinkComponent from "../../components/link-section/LinkComponent";
import ContactComponent from "../../components/contact/ContactComponent";

import ThermoformingImg1 from "../../assets/imgs/card-images/thermoforming-1.png";
import ThermoformingImg2 from "../../assets/imgs/card-images/thermoforming-2.png";
import ThermoformingImg3 from "../../assets/imgs/card-images/thermoforming-3.png";

import ThermoformingTable from "../../components/thermoforging-table/ThermoformingTable";

const ThermoformingPage = () => {
  const cardData = [
    {
      img: ThermoformingImg1,
      title: "Expertise",
      text: "Our skilled team of engineers and technicians use computer-aided design (CAD) software to create 3D models of the parts/molds, which are then meticulously crafted using our cutting-edge CNC machines.",
      orientation: false,
    },
    {
      img: ThermoformingImg2,
      title: "Materials",
      text: "We work with a variety of thermoplastic materials, including polycarbonate, ABS, PET, and PVC, and offer a range of finishes to meet your aesthetic and functional requirements.",
      orientation: true,
    },
    {
      img: ThermoformingImg3,
      title: "Process",
      text: "Our process is completed 100% in-house, unlike many other parts that require subcontracted laser work, or outside servicing like powder coating and painting. We can receive a customer's design, produce a mold, and spit out parts very quickly.",
      orientation: false,
    },
  ];

  const infoData = {
    text: "",
    video: "https://www.youtube.com/embed/hwOIJtxZKKY",
    thumbnail: HeroImg,
  };

  return (
    <div className="page-container">
      <section
        className="hero-section"
        style={{ backgroundImage: `url(${HeroImg})` }}
      >
        <div className="hero-shader"></div>
        <div className="hero-header">
          <h1>THERMOFORMING</h1>
          <h4>Where ideas take shape</h4>
        </div>
      </section>
      <section className="intro-section">
        <p>
          Our thermoforming services offer a cost-effective and efficient way to
          produce high-quality plastic parts and components. We use
          state-of-the-art technology to heat and mold plastic sheets into the
          desired shapes and sizes.
        </p>
        <div className="intro-section">
          <h3>What is thermoforming ideal for?</h3>
          <p>
            Thermoforming is ideal for creating parts with complex geometries or
            large dimensions and can be used for both low and high volume
            production runs. Trust us to provide expert thermoforming services
            that meet your unique needs.
          </p>
        </div>
      </section>
      <section className="card-container">
        <h2>HOW WE'RE DIFFERENT</h2>
        {cardData.map((card, key) => {
          return (
            <ManufacturingCard
              key={key}
              img={card.img}
              title={card.title}
              text={card.text}
              orientation={card.orientation}
            />
          );
        })}
      </section>
      <ThermoformingTable />
      <section className="info-section" style={{ marginTop: "0" }}>
        <VideoComponent data={infoData} />
      </section>
      <section>
        <LinkComponent />
      </section>
      <section>
        <ContactComponent />
      </section>
    </div>
  );
};

export default ThermoformingPage;
