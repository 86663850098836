import React from "react";

const OurEquipment = () => {
  return (
    <div className="fabrication-equipment">
      <div className="fabrication-equipment-intro">
        <h2>OUR EQUIPMENT</h2>
      </div>

      <div className="fabrication-equipment-list-container">
        <div className="fabrication-equipment-list-left">
          <div>
            <h3>File Import Capabilities</h3>
            <p>
              IGES, DXF, DWG, PRT, SLDPRT, VNC, GERBER, SAT, HPGL, ATS and many
              others
            </p>
          </div>
          <div>
            <h3>CNC Milling</h3>
            <ul>
              <li>
                HAAS EC500 - Dual Pallet Horizontall-(32X20X28)-20Hp 8,000RPM
              </li>
              <li>HAAS EC400 - Dual Pallet Horizontal-()-30Hp 12,000RPM</li>
              <li>
                HAAS EC400 4 + 1 axis - Dual Pallet Horizontal-(20X20X20)-30Hp
                12,000RPM
              </li>
              <li>V-60 Fanuc - ( 60 x 30 x 24 ) -30Hp. 8,000 rpm</li>
              <li>V-20iT Fanuc- ( 20 x 16 x 14 ) -15Hp 12,k000 rpm</li>
              <li>Mv-760 Fanuc - ( 30 x 20 x 20 ) -20Hp 8,000 rpm</li>
              <li>HASS TM1 - ( 30 x 12 x 16 ) -7.5Hp-6,000 rpm</li>
              <li>HASS TM2 - ( 40 x 16 x 16 ) -7.5Hp-6,000 rpm</li>
              <li>Brother S100X1 - ( 39.4 x 19.7 x 11.8 ) -15,000 rpm</li>
              <li>HAAS Super VF-3 - ( 40 x 16 x 25 ) -30Hp - 12,0000 rpm</li>
              <li>KITAMURA 4XD Fanuc - ( 40 x 20 x 22 ) 35Hp. 12,000 rpm</li>
              <li>V-50i Fanuc- ( 50 x 25 x 24 ) 30Hp. 12,000 rpm</li>
              <li>HAAS VF-3 ( 30 x 16 x 18 ) 15Hp. 7,500 rpm</li>
              <li>HAAS VF-2 ( 20 x 12 x 16) 20Hp. 10,000 rpm</li>
            </ul>
          </div>
          <div>
            <h3>Thermoforming</h3>
            <ul>
              <li>
                Sibe 48" x 48" Single Station Vacuum Former Single Station
                Thermoformer
              </li>
            </ul>
          </div>
          <div>
            <h3>Sheet Metal Fabrication</h3>
            <ul>
              <li>DDM-5020 50 Ton Dener Servo Electric Press Brake - 48"</li>
              <li>618PLUS-L Haeger 208/240 Volt Insertion Press</li>
            </ul>
          </div>
        </div>
        <div className="fabrication-equipment-list-right">
          <div>
            <h3>CNC Turning</h3>
            <ul>
              <li>
                Mori Seiki NL2500SY - Dual Spindle, 3.1 Bore, 14 in. Max Turning
                Diameter, 27.7 in. Max Turning Length.
              </li>
              <li>
                HAAS S120 - 2in. bar capacity, 10 in. max turn dia., 20Hp.-_4000
              </li>
            </ul>
          </div>
          <div>
            <h3>Quality Assurance</h3>
            <ul>
              <li>
                Brown & Sharpe "ONE" CMM, 2009, 28x40x28 Travel, Articulating
                Probe
              </li>
              <li>
                Hexagon CMM, HP-L-5.8 Laser scanning sensor Articulating Probe,
                27x39x25 table travel
              </li>
              <li>Brown & Sharpe - Tesa Hite 600</li>
              <li>Brown & Sharpe - Tesa Hite 700</li>
              <li>Brown & Sharpe - Tesa Hite 900</li>
              <li>Starrett - Grade A Inspection Surface Plate X3</li>
            </ul>
          </div>
          <div>
            <h3>Other</h3>
            <ul>
              <li>Complete Assembly Capabilities</li>
              <li>Manual Lathe St.-1440 Microcut</li>
              <li>Manual Mill Bridgeport</li>
              <li>Band Saw Do-All 2013-V</li>
              <li>HE&M Saw H90A-4</li>
              <li>Skat Blast Bead-Blaster</li>
              <li>Kent Surface Grinder KGS-510AX 18x38x12 400lbs</li>
              <li>Kent Surface Grinder SGS-1640AHD</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurEquipment;
