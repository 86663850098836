import React from "react";

import "../../assets/styles/template.css";
import "../../assets/styles/template-mobile.css";

const ManufacturingCard = ({ img, title, text, orientation }) => {
  return (
    <div
      className="info-card"
      style={
        orientation
          ? { flexDirection: "row-reverse" }
          : { flexDirection: "row" }
      }
    >
      <img className="info-card-img" src={img} alt="card" />
      <div className="info-card-data">
        <h3>{title}</h3>
        <p>{text}</p>
      </div>
    </div>
  );
};

export default ManufacturingCard;
