import { useState } from "react";
import { Link } from "react-router-dom";
import { IoMdArrowDropright } from "react-icons/io";
import SocialLinks from "./SocialLinks";

const MobileMenu = ({ func, mobileMenuShow }) => {
  const [showDropDown, setShowDropDown] = useState(false);

  const mobileDropDownFunc = () => {
    setShowDropDown(!showDropDown);
  };

  return (
    <div
      className={
        mobileMenuShow
          ? "mobile-menu mobile-menu-show"
          : "mobile-menu mobile-menu-hide"
      }
    >
      <div className="mobile-menu-head">
        <ul>
          <li
            style={{ display: "flex", alignItems: "center" }}
            onClick={() => mobileDropDownFunc()}
          >
            <Link onClick={() => func()} to="/custom-manufacturing">
              CUSTOM MANUFACTURING
            </Link>
            <IoMdArrowDropright
              className={
                showDropDown ? "dropdown-arrow rotate" : "dropdown-arrow"
              }
            />
          </li>
          <hr />
          <div
            className={
              showDropDown
                ? "mobile-menu-dropdown show-dropdown"
                : "mobile-menu-dropdown"
            }
            onClick={() => func()}
          >
            <Link to="/equipment">Equipment</Link>
            <Link to="/metal-bending">Sheet Metal Bending</Link>
            <Link to="/cable-assembly">Cable Assembly</Link>
            <Link to="/quality-assurance">Quality Assurance</Link>
            <Link to="/cnc">CNC</Link>
            <Link to="/thermoforming">Thermoforming</Link>
          </div>
          <li onClick={() => func()}>
            <Link to="/enclosures">ENCLOSURES</Link>
          </li>
          <li onClick={() => func()}>
            <Link to="/products">PRODUCTS</Link>
          </li>
          <li onClick={() => func()}>
            <Link to="/contact">CONTACT</Link>
          </li>
        </ul>
      </div>
      <SocialLinks mobile={true} />
    </div>
  );
};

export default MobileMenu;
