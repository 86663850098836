import React from "react";

import Contact from "../../components/contact/Contact";

import "../../assets/styles/contact.css";

const ContactPage = () => {
  return (
    <div className="contact-page">
      <section
        style={{
          maxWidth: "1320px",
          width: "80%",
          // textAlign: "center",
          textShadow: "none",
        }}
      >
        <p style={{ margin: "25px 0" }}>
          If our off-the-shelf options don't quite fit your needs, don't
          hesitate to give us a call. Because we build everything in-house,
          including the molds used to form our enclosures, we can
          cost-effectively build our enclosures at small volumes. This means
          that we have the flexibility to meet your specific requirements and
          deliver customized solutions that meet your unique needs.
        </p>
        <p>As we like to say, "if you can imagine it, we can build it".</p>
      </section>
      <Contact />
    </div>
  );
};

export default ContactPage;
