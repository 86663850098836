import React from "react";
import { Link } from "react-router-dom";

const notFound = () => {
  const homeBtnStyle = {
    margin: "30px",
    backgroundColor: "var(--brownish-orange)",
    color: "var(--font-color-two)",
    padding: "10px 15px",
    borderRadius: "10px",
  };

  const notFoundStyles = {
    width: "100%",
    height: "70vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <div style={notFoundStyles}>
      <h1 style={{ color: "black" }}>You've found a page that doesn't exist</h1>
      <Link to="/" style={homeBtnStyle}>
        BACK TO HOME
      </Link>
    </div>
  );
};

export default notFound;
