import { useEffect, useState } from "react";

import CollectionCard from "../../components/collection/CollectionCard";
import EnclosureData from "../../util/data/all-products/EnclosureData";
import ApCovers from "../../util/data/all-products/APCovers";
import MountingBrackets from "../../util/data/all-products/MountingBrackets";
import ContactComponent from "../../components/contact/ContactComponent";

import "../../assets/styles/enclosure-page.css";

const Enclosures = () => {
  const [selectedProduct, setSelectedProduct] = useState("Enclosures");
  const productList = ["Enclosures", "Vanity Covers", "Mounting Brackets"];
  const [dataSet, SetDataSet] = useState(EnclosureData);

  useEffect(() => {
    if (sessionStorage.getItem("selectedProduct")) {
      setSelectedProduct(sessionStorage.getItem("selectedProduct"));
    }
  }, []);

  const handleClick = (product) => {
    // console.log(product);
    setSelectedProduct(product);
    sessionStorage.setItem("selectedProduct", product);
  };

  useEffect(() => {
    // console.log(selectedProduct);
    if (selectedProduct === "Enclosures") {
      SetDataSet(EnclosureData);
    } else if (selectedProduct === "Vanity Covers") {
      SetDataSet(ApCovers);
    } else if (selectedProduct === "Mounting Brackets") {
      SetDataSet(MountingBrackets);
    }
  }, [selectedProduct]);

  return (
    <>
      <div className="enclosure-page">
        {/* <h3 style={{ fontSize: "34px", marginBottom: "45px" }}>Products</h3> */}

        <ul className="enclosure-page-select-products">
          {productList.map((product, key) => {
            return (
              <li
                style={
                  selectedProduct === product
                    ? {
                        color: "var(--brownish-orange)",
                        borderBottom: "5px solid var(--brownish-orange)",
                        marginBottom: "0",
                      }
                    : {}
                }
                key={key}
                onClick={() => handleClick(product)}
              >
                {product.toUpperCase()}
              </li>
            );
          })}
        </ul>

        {selectedProduct === "Enclosures" && (
          <p>
            Our enclosures are the go-to-solution in stadiums and arenas, where
            tens-of-thousands of our handrail, under seat, wall mountable, and
            ceiling mountable systems are found.
          </p>
        )}
        {selectedProduct === "Vanity Covers" && (
          <p>
            Seamlessly merge technology and aesthetics with our access point
            vanity covers.
          </p>
        )}
        {selectedProduct === "Mounting Brackets" && (
          <p>
            Elevate your installation game with our sturdy mounting brackets –
            precision, strength, and ease in one.
          </p>
        )}

        <div className="collection-container">
          <div>
            {dataSet.map((collection, key) => {
              return (
                <CollectionCard
                  collection={collection}
                  key={key}
                  index={key}
                  selectedProduct={selectedProduct}
                />
              );
            })}
          </div>
        </div>
      </div>
      <ContactComponent />
    </>
  );
};

export default Enclosures;
