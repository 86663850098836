import BR09705PDF from "../../../assets/enclosure-pdf/BR090705.pdf";
import BR100604PDF from "../../../assets/enclosure-pdf/BR100604.pdf";
import TileSaddlePdf from "../../../assets/enclosure-pdf/tile-saddle-bracket.pdf";

import ImgPlaceholder from "../../../assets/imgs/icons/image-placeholder.png";

import TileSaddle1 from "../../../assets/imgs/enclosure-photos/Tile Saddle/IMG_0532.png";
import TileSaddle2 from "../../../assets/imgs/enclosure-photos/Tile Saddle/IMG_0580.png";
import TileSaddle3 from "../../../assets/imgs/enclosure-photos/Tile Saddle/IMG_0582.png";

import Clamshell1 from "../../../assets/imgs/enclosure-photos/clamshell/IMG_0502.png";
import Clamshell2 from "../../../assets/imgs/enclosure-photos/clamshell/IMG_0506.png";
import Clamshell3 from "../../../assets/imgs/enclosure-photos/clamshell/IMG_0513.png";
import Clamshell4 from "../../../assets/imgs/enclosure-photos/clamshell/IMG_0518.png";

import BR100604_1 from "../../../assets/imgs/enclosure-photos/BR100604 - _L_bracket/BR100604-01.png";
import BR100604_2 from "../../../assets/imgs/enclosure-photos/BR100604 - _L_bracket/BR100604-02.png";
import BR100604_3 from "../../../assets/imgs/enclosure-photos/BR100604 - _L_bracket/BR100604-03.png";

const clamshellBracket = {
  id: 3,
  partNum: "BR090705",
  dimensions: "",
  title: "“Clamshell” Wall Bracket",
  img: Clamshell1,
  pdf: BR09705PDF,
  description:
    "AmpThink’s BR090705 is designed specifically for mounting common Access Points in indoor or shielded environments. The simple design allows for cost-effective AP deployment that both gets the job done and looks great after installation. The Clamshell bracket features our universal hole pattern fitting most common APs in the market, with a vanity cover that discreetly conceals unsightly cables. Our simple “keyhole” pattern on the back is easy to install with common bolts and washers.",
  technicalSpecs: ["Dimensions: 9” x 7” x 5”", "Weight: 3.8lbs"],
  kitIncludes: [],
  kitOptions: [],
  features: [
    "Constructed from heavy-duty 20 gauge steel with a scratch- proof powdered finish.",
    "Features two 1/2” knock-outs for easy integration with conduit",
    "Designed to fit access points from a range of manufacturers including Cisco, Aruba, and Extreme.",
    "A matching vanity shroud that conceals cables",
    "Perfect for mounting an access point in an indoor or shielded environment",
  ],
  kitIncludesData: [
    { img: Clamshell1, title: "“Clamshell” Wall Bracket" },
    { img: ImgPlaceholder, title: "tba" },
  ],
  imgArr: [Clamshell1, Clamshell2, Clamshell3, Clamshell4],
  pathname: "/AMFG-Website-products-single/BR090705",
};

const wallBracket = {
  id: 4,
  partNum: "BR100604",
  dimensions: "",
  title: "“L” Wall Bracket",
  img: BR100604_1,
  pdf: BR100604PDF,
  description:
    "AmpThink’s BR100604 is a cost-effective solution for mounting common access points in indoor or shielded environments where aesthetics are not a concern. The L bracket features our universal hole pattern fitting most common APs in the market, with a simple “keyhole” pattern on the back for seamless installation with bolts and washers.",
  technicalSpecs: [
    'Outside Dimensions: 9" x 7" x 5"',
    "Weight: 2lbs",
    "Material: 20GA ASTM A1008 Cold Rolled Steel, powder coated finish",
  ],
  kitIncludes: [],
  kitOptions: [],
  features: [
    "Constructed from heavy-duty 20 gauge steel with a scratch- proof powdered finish.",
    "Designed to fit access points from a range of manufacturers including Cisco, Aruba, and Extreme.",
    "Perfect for mounting an access point in an indoor or shielded environment",
  ],
  kitIncludesData: [
    { img: ImgPlaceholder, title: "“L” Wall Bracket" },
    { img: ImgPlaceholder, title: "tba" },
  ],
  imgArr: [BR100604_1, BR100604_2, BR100604_3],
  pathname: "/AMFG-Website-products-single/BR100604",
};

const TileSaddle = {
  id: 16,
  partNum: "CT241103",
  dimensions: "tba",
  title: "Tile Saddle Bracket",
  img: TileSaddle1,
  pdf: TileSaddlePdf,
  description:
    "The Ceiling Tile Saddle Bracket for Wireless Access Points is a discreet, easy-to-install solution that optimizes Wi-Fi coverage while maintaining a clean aesthetic in drop ceiling environments.",
  technicalSpecs: [
    "Outside Dimensions: 23.921” x 10.5” x 2.5” ",
    "Weight: 5lbs",
    "Materials: 19 Gauge powder coated Steel",
  ],
  compatibleHardware: [
    [
      "Cisco Access Points",
      "• 3702I",
      "• 3702E",
      "• 3702P",
      "• 3802I",
      "• 3802E",
      "• 3802P",
      "• C9120AXI",
      "• C9120AXE",
      "• C9120AXP",
      "• C9130AXI",
    ],
    ["Aruba Access Points", "• 400 Series", "• 500 Series"],
    [
      "Cisco Antennas:",
      "• IR-ANT2524DW-RS=",
      "• AIR-ANT2524DW-RS",
      "• AIR-ANT2588P3M-N=",
      "• AIR-ANT2566P4W-RS=",
    ],
    ["Aruba Antennas:", "• JW009A", "• JW011A"],
  ],
  kitIncludes: [],
  kitOptions: [],
  features: [],
  kitIncludesData: [
    { img: TileSaddle1, title: "Tile Saddle Bracket" },
    { img: ImgPlaceholder, title: "tba" },
  ],
  imgArr: [TileSaddle1, TileSaddle2, TileSaddle3],
  pathname: "/AMFG-Website-products-single/CT241103",
};

const MountingBrackets = [clamshellBracket, wallBracket, TileSaddle];

export default MountingBrackets;
