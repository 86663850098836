import { useState } from "react";
import { Link } from "react-router-dom";

import "./collection.css";

const CollectionCard = ({ collection, index, selectedProduct }) => {
  const [hover, setHover] = useState(false);
  const handleHoverEvent = () => {
    setHover(true);
  };

  const handlHoverEnd = () => {
    setHover(false);
  };

  return (
    <div
      className="collection-card-container"
      onMouseEnter={() => handleHoverEvent()}
      onMouseLeave={() => handlHoverEnd()}
    >
      <div
        className={
          hover
            ? "collection-card-img-container orange-border"
            : "collection-card-img-container"
        }
      >
        <Link
          to={{
            pathname: collection.pathname + "/" + selectedProduct + "/" + index,
            state: { data: collection.title, key: index },
          }}
          state={collection}
        >
          <img
            className="collection-card-img"
            src={collection.img}
            alt="collection"
          />
        </Link>
      </div>
      <Link
        to={{
          pathname: collection.pathname + "/" + selectedProduct + "/" + index,
          state: { data: collection.title },
        }}
        state={collection}
      >
        <div
          className={
            hover
              ? "collection-card-content-container orange-background"
              : "collection-card-content-container"
          }
        >
          <h4>{collection.title}</h4>
          {collection.partNum && <p>{collection.partNum}</p>}
        </div>
      </Link>
    </div>
  );
};

export default CollectionCard;
