import React from "react";
import { Link } from "react-router-dom";

import { IoMdArrowDropright } from "react-icons/io";

const LinkComponent = () => {
  const links = [
    {
      name: "Equipment",
      link: "/equipment",
    },
    {
      name: "Quality Assurance",
      link: "/quality-assurance",
    },
    {
      name: "Sheet Metal Bending",
      link: "/metal-bending",
    },
    {
      name: "CNC",
      link: "/cnc",
    },
    {
      name: "Cable Assembly",
      link: "/cable-assembly",
    },
    {
      name: "Thermoforming",
      link: "/thermoforming",
    },
  ];

  return (
    <div className="link-component">
      <h2>MORE CAPABILITIES</h2>
      <div className="link-container">
        {links.map((link, key) => {
          return (
            <div key={key} className="link-container-container">
              <div>
                <Link to={link.link}>
                  <h3 style={{ margin: "0" }}>{link.name}</h3>
                  <IoMdArrowDropright className="link-component-arrow" />
                </Link>
              </div>
              <hr />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default LinkComponent;
