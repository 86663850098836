import pdf6 from "../../../assets/enclosure-pdf/pdf-6.pdf";

import AC001PDF from "../../../assets/enclosure-pdf/AC001.pdf";
import GP141204PDF from "../../../assets/enclosure-pdf/GP141204.pdf";
import GP141205PDF from "../../../assets/enclosure-pdf/GP141205.pdf";
import GP231504PDF from "../../../assets/enclosure-pdf/GP231504.pdf";

import IP141204PDF from "../../../assets/enclosure-pdf/IP141204.pdf";
import IP141205PDF from "../../../assets/enclosure-pdf/IP141205.pdf";
import IP231504PDF from "../../../assets/enclosure-pdf/IP231504.pdf";

import ImgPlaceholder from "../../../assets/imgs/icons/image-placeholder.png";
import EnclosureHeader from "../../../assets/imgs/enclosure-photos/series-header-image.png";
import IndoorEnclosureHeader from "../../../assets/imgs/enclosure-photos/indoor header image.png";

import ShortStaticFeet1 from "../../../assets/imgs/enclosure-photos/Short - static feet/IMG_0373.png";
import ShortStaticFeet2 from "../../../assets/imgs/enclosure-photos/Short - static feet/IMG_0374.png";
import ShortStaticFeet3 from "../../../assets/imgs/enclosure-photos/Short - static feet/IMG_0376.png";
import ShortStaticFeet6 from "../../../assets/imgs/enclosure-photos/Short - static feet/IMG_0398.png";

import ShortSwivelFeet1 from "../../../assets/imgs/enclosure-photos/Short - swivel feet/IMG_0463.png";

import TallStaticFeet1 from "../../../assets/imgs/enclosure-photos/Tall - static feet/IMG_0331.png";
import TallStaticFeet3 from "../../../assets/imgs/enclosure-photos/Tall - static feet/IMG_0340.png";
import TallStaticFeet5 from "../../../assets/imgs/enclosure-photos/Tall - static feet/IMG_0346.png";
import TallSwivelFeet3 from "../../../assets/imgs/enclosure-photos/Tall - swivel feet/IMG_0415.png";

import Bracket1 from "../../../assets/imgs/enclosure-photos/Brackets/IMG_0318.png";
import Bracket2 from "../../../assets/imgs/enclosure-photos/Brackets/IMG_0321.png";
import Bracket3 from "../../../assets/imgs/enclosure-photos/Brackets/IMG_0327.png";
import Bracket4 from "../../../assets/imgs/enclosure-photos/Brackets/IMG_0584.png";
import Bracket5 from "../../../assets/imgs/enclosure-photos/Brackets/IMG_0587.png";

import UnderSeat1 from "../../../assets/imgs/enclosure-photos/underseat-enclosure/underseat-1.png";
import UnderSeat2 from "../../../assets/imgs/enclosure-photos/underseat-enclosure/underseat-2.png";
import UnderSeat3 from "../../../assets/imgs/enclosure-photos/underseat-enclosure/underseat-3.png";
import UnderSeat4 from "../../../assets/imgs/enclosure-photos/underseat-enclosure/underseat-4.png";
import UnderSeat5 from "../../../assets/imgs/enclosure-photos/underseat-enclosure/underseat-5.png";

import QuadGland1 from "../../../assets/imgs/enclosure-photos/Quad Gland/quadgland-1.png";
import QuadGland2 from "../../../assets/imgs/enclosure-photos/Quad Gland/quadgland-2.png";
import QuadGland3 from "../../../assets/imgs/enclosure-photos/Quad Gland/quadgland-3.png";
import QuadGland4 from "../../../assets/imgs/enclosure-photos/Quad Gland/cable-gland-explode.png";
import QuadGland5 from "../../../assets/imgs/enclosure-photos/Quad Gland/quadgland-5.png";
import QuadGland6 from "../../../assets/imgs/enclosure-photos/Quad Gland/quadgland-6.png";

import GP2315041 from "../../../assets/imgs/enclosure-photos/gp231504/GP231504-01.png";
import GP2315042 from "../../../assets/imgs/enclosure-photos/gp231504/GP231504-02.png";
import GP2315043 from "../../../assets/imgs/enclosure-photos/gp231504/GP231504-03.png";
import GP2315044 from "../../../assets/imgs/enclosure-photos/gp231504/GP231504-04.png";
import GP2315045 from "../../../assets/imgs/enclosure-photos/gp231504/GP231504-05.png";
import GP2315046 from "../../../assets/imgs/enclosure-photos/gp231504/GP231504-06.png";

import IP141204_1 from "../../../assets/imgs/enclosure-photos/IP141204/IMG_0536.png";
import IP141204_2 from "../../../assets/imgs/enclosure-photos/IP141204/IMG_0542.png";
import IP141204_3 from "../../../assets/imgs/enclosure-photos/IP141204/IMG_0551.png";
import IP141204_4 from "../../../assets/imgs/enclosure-photos/IP141204/IMG_0554.png";
import IP141204_5 from "../../../assets/imgs/enclosure-photos/IP141204/IMG_0559.png";
import IP141204_6 from "../../../assets/imgs/enclosure-photos/IP141204/IMG_0566.png";
import IP141204_7 from "../../../assets/imgs/enclosure-photos/IP141204/IMG_0567.png";
import IP141204_8 from "../../../assets/imgs/enclosure-photos/IP141204/IMG_0568.png";
import IP141204_9 from "../../../assets/imgs/enclosure-photos/IP141204/IMG_0571.png";
import IP141204_10 from "../../../assets/imgs/enclosure-photos/IP141204/IMG_0575.png";
import IP141204_11 from "../../../assets/imgs/enclosure-photos/IP141204/IMG_0576.png";

import IP141205_1 from "../../../assets/imgs/enclosure-photos/IP141205/IP141205-01.png";
import IP141205_2 from "../../../assets/imgs/enclosure-photos/IP141205/IP141205-02.png";
import IP141205_3 from "../../../assets/imgs/enclosure-photos/IP141205/IP141205-03.png";

import IP231504_1 from "../../../assets/imgs/enclosure-photos/IP231504/IP231504-01.png";
import IP231504_2 from "../../../assets/imgs/enclosure-photos/IP231504/IP231504-02.png";
import IP231504_3 from "../../../assets/imgs/enclosure-photos/IP231504/IP231504-03.png";
import IP231504_4 from "../../../assets/imgs/enclosure-photos/IP231504/IP231504-05.png";
import IP231504_5 from "../../../assets/imgs/enclosure-photos/IP231504/IP231504-06.png";

// install guides
import gp231504InstallGuide from "../../../assets/install-guides/GP231504 Installation Guide.pdf";
import gp141204InstallGuide from "../../../assets/install-guides/GP141204 Installation Guide.pdf";
import gp141205InstallGuide from "../../../assets/install-guides/GP141205 Installation Guide.pdf";
import ip141204InstallGuide from "../../../assets/install-guides/IP141204 Installation Guide.pdf";
import ip141205InstallGuide from "../../../assets/install-guides/IP141205 Installation Guide.pdf";
import ip231504InstallGuide from "../../../assets/install-guides/IP231504 Installation Guide.pdf";

const GP1 = {
  partNum: "GP231504",
  dimensions: '23" x 15" x 04"',
  title: "General Purpose Enclosure",
  showcaseTitle:
    '23" X 15" X 04" GENERAL PURPOSE NEMA 4X, IP 66 OUTDOOR ENCLOSURE',
  img: GP2315041,
  installGuide: gp231504InstallGuide,
  pdf: GP231504PDF,
  description:
    " Electronics – access points, antennas, radios, etc. often need to be mounted in challenging environments where environmental conditions and/or security needs encourage the use of a protective enclosure. All the enclosures in AmpThink’s General Purpose Enclosure (“GPE”) line are suitable for protecting your equipment from both the weather and tamper. Designed for a slim “side-by-side” configuration, the 23” x 15” box was designed around common WiFi access point/antenna dimensions and offers multiple configuration options to meet your needs. All AmpThink GPEs utilize our patented folding frame technology featuring dual weather seals, tool-less bracket installation, and modular mounting options.",
  protect:
    "The 23” x 15” NEMA 4X, IP66 outdoor enclosure is designed with impact resistant ABS plastic for indoor and outdoor deployments. The patented folded-frame gasket design and overlapping lid offer maximum protection from wind-blown dust, pressure washing, and UV rays. This dual weather seal design provides confidence for the IT professional that their equipment will last, no matter the environment.",
  simplicity:
    "Leveraging years of experience and product evolution, our 23” x 15” enclosure is designed with the installer in mind. Our uniquely designed internal brackets simply snap into the enclosure, no tools required.  Featuring a tilting internal antenna bracket, the installer is provided an additional 180 degrees of antenna pitch as needed. Our external mounting feet can be positioned on either side of the enclosure, simplifying installation in tight spaces. This enclosure will protect your equipment and save installers time in the field.",
  technicalSpecs: [
    'Outside Dimensions: 23.07" x 14.39" x 4.05"',
    'Inside Dimensions: 20.8" x 12.1" x 3.8"',
    "Weight: 5.6lbs fully assembled",
  ],
  kitIncludes: [
    "Enclosure Base with patented AmpThink Folded Frame Mounting Feet ",
    "Tool-less bracket for access point ",
    "12 (insert size) torx lid screws ",
  ],
  kitOptions: [
    "Tilting Antenna mounting bracket for mounting external antennas ",
    "UL Listed plastic ",
  ],
  features: [
    "NEMA 4X, IP66 Rated, protecting your equipment from wind-blown dust and pressure washing. ",
    "Constructed from impact resistant, RF transparent ABS plastic. ",
    "Available in both fire-retardant UL listed UL-945VA ABS Plastic or general-purpose UV resistant ABS. ",
    "Ideal for mounting access points and/or antennas in challenging outdoor environments including stadiums and warehouses. ",
    "Designed to protect access points from a range of manufacturers including Cisco, Aruba, and Extreme. ",
    "Lid is secured with tamper resistant Torx screws. ",
    "Perfect for mounting both an access point and external antenna side-by-side in the same enclosure. ",
    "Available with a tilting internal antenna mount allowing up to 180 degrees of rotation. ",
    "Features an overlapping lid which protects the gasket from high pressure water (pressure washing or wind driven rain) to ensure dry electronics even in the harshest environments ",
  ],
  keyFeatures:
    'Designed for a slim "side-by-side" configuration, ideal for mounting access points and/or antennas in challenging outdoor environments including stadiums and warehouses.',
  imgArr: [GP2315041, GP2315042, GP2315043, GP2315044, GP2315045, GP2315046],
};

const GP2 = {
  partNum: "GP141205",
  dimensions: '14" x 12" x 05"',
  title: "General Purpose Enclosure",
  showcaseTitle:
    '14" X 12" X 05" GENERAL PURPOSE NEMA 4X, IP 66 OUTDOOR ENCLOSURE',
  img: TallStaticFeet3,
  pdf: GP141205PDF,
  installGuide: gp141205InstallGuide,
  description:
    'Electronics - access points, antennas, radios, etc. often need to be mounted in challenging environments where environmental conditions and/or security needs dictate the use of a protective enclosure. All of the enclosures in AmpThink General Purpose Enclosure ("GPE") line are suitable for protecting your equipment from both the weather and tamper. AmpThink GPEs are designed around AmpThink patented folding frame technology featuring dual weather seals, tool-less bracket installation, and modular mounting options.',
  protect:
    "The 14” x 12” x 5” NEMA 4X, IP66 outdoor enclosure is made with impact and UV resistant ABS/ASA plastic, capable of withstanding any potential kicks, spills, or drops. The patented folded-frame gasket design and overlapping lid offer maximum protection from wind-blown dust, pressure washing, and UV rays. The thermoformed ASA/ABS housing and patented dual weather seal provide confidence for the IT professional that their equipment will last, no matter the environment.",
  simplicity:
    "Leveraging years of experience and product evolution, the 14” x 12” x 5” enclosure is designed by installers for installers. Our uniquely designed internal brackets simply snap into the enclosure, no tools required. Our external mounting feet can be positioned on either side of the enclosure, simplifying installation in tight spaces. Featuring an optional tilting external mount, the installer is provided an additional 180 degrees of antenna pitch as needed. This enclosure will protect your equipment and save installers time in the field.",
  technicalSpecs: [
    'Outside Dimensions: 13.96" x 12.03" x 4.83"',
    'Inside Dimensions: 11.5" x 9.6" x 4.6"',
    "Weight: 4lbs fully assembled",
  ],
  kitIncludes: [
    "Enclosure Base with patented AmpThink Folded Frame",
    "Static Mounting Feet",
    "Tool-less bracket for access point",
    '12 x 10-32 3/8" pin-in-torx screws',
  ],
  kitOptions: [
    "Tilting external mounting bracket for additional antenna pitch",
    "UL Listed, flame retardant plastic",
  ],
  features: [
    "NEMA 4X, IP66 Rating, ensuring protection from wind-blown dust and pressure washing. ",
    "Available in both fire-retardant UL listed UL-945VA plastic or general-purpose UV and impact resistant, RF transparent ASA/ABS plastic. ",
    "Ideal for mounting an access point and antenna in a single “stacked” configuration in the same enclosure. ",
    "Designed around a range of manufacturers including Cisco, Aruba, and Extreme. ",
    "The lid is secured with tamper resistant pin-in-torx screws.",
    "Available with tilting external mounting feet allowing up to 180 degrees of rotation.",
    "The mounting feet can be installed on either side of the enclosure’s base ",
  ],
  keyFeatures:
    'Ideal for mounting an access point and antenna in a single "stacked" configuration in the same enclosure.',
  imgArr: [
    TallStaticFeet3,
    TallStaticFeet5,
    TallStaticFeet1,
    TallSwivelFeet3,
    ShortStaticFeet6,
    Bracket1,
    Bracket4,
    Bracket2,
    Bracket5,
  ],
};

const GP3 = {
  partNum: "GP141204",
  dimensions: '14" x 12" x 04"',
  title: "General Purpose Enclosure",
  showcaseTitle:
    '14" X 12" X 04" GENERAL PURPOSE NEMA 4X, IP 66 OUTDOOR ENCLOSURE',
  img: ShortStaticFeet1,
  pdf: GP141204PDF,
  installGuide: gp141204InstallGuide,
  description:
    "Electronics (access points, antennas, radios, etc.) often need to be mounted in in challenging environments where conditions call for the use of a protective enclosure. All the enclosures in AmpThink’s General Purpose Enclosure (“GPE”) line are suitable for protecting your equipment from windblown dust, tamper, and power washing. The 14” x 12” x 5” GP Enclosure was designed around common WiFi access point/antenna dimensions and offers multiple configuration options to meet your needs. This enclosure is best suited for installing a single AP/antenna on a wall, fence, or on the floor. All AmpThink GPEs utilize our patented folding frame technology featuring dual weather seals, tool-less bracket installation, and modular mounting options. ",
  protect:
    "The 14” x 12” x 4” NEMA 4X, IP66 outdoor enclosure is made with impact and UV resistant ABS/ASA plastic, capable of withstanding any potential kicks, spills, or drops. The patented folded-frame gasket design and overlapping lid offer maximum protection from wind-blown dust, pressure washing, and UV rays. The thermoformed ASA/ABS housing and patented dual weather seal provide confidence for the IT professional that their equipment will last, no matter the environment.",
  simplicity:
    "Leveraging years of experience and product evolution, the 14” x 12” x 4” enclosure is designed by installers for installers. Our uniquely designed internal brackets simply snap into the enclosure, no tools required. Our external mounting feet can be positioned on either side of the enclosure, simplifying installation in tight spaces. Featuring an optional tilting external mount, the installer is provided an additional 180 degrees of antenna pitch as needed. This enclosure will protect your equipment and save installers time in the field.",
  technicalSpecs: [
    'Outside Dimensions: 13.96"x 12.03"x 3.54',
    'Inside Dimensions: 11.5" x 9.6" x 3.3"',
    "Weight: 4 lbs fully assembled",
  ],
  kitIncludes: [
    "Enclosure Base with patented AmpThink Folded Frame",
    "Static Mounting Feet",
    "Tool-less bracket for access point mounting",
    '12 x 10-32 x 3/8" pin-in-torx screws',
  ],
  kitOptions: [
    "Tilting external mounting bracket for additional antenna pitch",
    "UL Listed, flame retardant plastic",
  ],
  features: [
    "NEMA 4X IP66 Rating, ensuring protection from wind-blown dust and pressure washing.",
    "Available in both fire-retardant UL listed UL-945VA plastic or general-purpose UV and impact resistant, RF transparent ASA/ABA plastic.",
    'Ideal for mounting an access point and antenna in a single "stacked" configuration in the same enclosure.',
    "Designed around a range of manufacturers including Cisco, Aruba, and Extreme.",
    "The lid is secured with tamper resistant pin-in-torx screws.",
    "Available with tilting external mounting feet allowing up to 180 degrees of rotation.",
    "The mounting feet can be installed on either side of the enclosure's base",
  ],
  keyFeatures:
    "Ideal for mounting access points and/or antennas in challenging environments including stadiums and warehouses.",
  imgArr: [
    ShortStaticFeet1,
    ShortStaticFeet3,
    ShortStaticFeet2,
    ShortSwivelFeet1,
    ShortStaticFeet6,
    Bracket3,
  ],
};

const OutdoorSeries = {
  partNum: null,
  dimensions: "tba",
  title: "General Purpose Outdoor Series",
  line: "GPE Outdoor Line",
  img: EnclosureHeader,
  imgArr: [],
  encArr: [GP3, GP2, GP1],
  pathname: "/AMFG-Website-products-series/General Purpose Outdoor Series",
  headerImage: EnclosureHeader,
  header: "General Purpose Enclosure Outdoor Series",
  headerInfo:
    "Protect your electronic investments - durable, long-lasting and weather resistant in the harshest environments.",
};

const IP1 = {
  partNum: "IP231504",
  dimensions: '23" x 15" x 04"',
  title: "General Purpose Enclosure",
  showcaseTitle: "23” x 15” x 4” LIGHT DUTY INDOOR ENCLOSURE​ ",
  img: IP231504_1,
  pdf: IP231504PDF,
  installGuide: ip231504InstallGuide,
  description:
    "Access points and antennas often need to be mounted in locations that warrant the use of a protective enclosure. The 23” x 15” x 4” Light Duty Indoor Enclosure offers the installer time-saving features and provides intelligent solutions to your protection, coverage, and aesthetic concerns. This enclosure supplies additional protection from dust, moisture, and everyday wear. IP231504. All AmpThink Light Duty Enclosures utilize our patented folding frame technology featuring dual weather seals, tool-less bracket installation, and modular mounting options.",
  protect:
    " The IP231504 enclosure is made with impact and UV resistant ABS/ASA plastic, capable of withstanding any potential kicks or drops. The patented folded-frame gasket design and overlapping lid offer additional protection from dust, moisture, and UV rays. These features provide confidence for the IT professional that their equipment will last.",
  simplicity:
    "Leveraging years of experience and product evolution, the IP231504 enclosure is designed for installers by installers. Our enclosure eliminates common issues faced during installation. The uniquely designed internal brackets simply snap into the enclosure, no tools required. Our snap-on lid provides instant access into the enclosure during install or future tuning/survey work. Our external mounting feet can be positioned on either side of the enclosure, simplifying installation in tight spaces. Featuring a tilting internal antenna mount, the installer is provided an additional 180 degrees of antenna pitch as needed for their application. This enclosure will protect your equipment and save installers time and headaches in the field.",
  technicalSpecs: [
    'Outside Dimensions: 23.07" x 14.39" x 4.05"',
    'Internal Dimensions: 20.8" x 12.1" 3.8"',
    "Weight: 5.6lbs fully assembled",
    "Ambient Temperature Range of Plastic: -4 degrees F to 176 degrees F",
  ],
  kitIncludes: [
    "Enclosure Base with patented AmpThink Folded Frame",
    "Static Mounting Feet  ",
    "Tool-less bracket for access point and antenna  ",
    "Tether accessory for snap-on lid ",
  ],
  kitOptions: ["UL Listed, flame retardant plastic "],
  features: [
    "Patented folded gasket frame, providing additional protection from dust, moisture, and wear. ",
    "Available in both fire-retardant UL listed UL-945VA plastic or general-purpose UV and impact resistant, RF transparent ASA/ABS plastic. ",
    "Ideal for mounting access points and/or antennas in indoor environments. ",
    "Designed around a range of manufacturers including Cisco, Aruba, and Extreme. ",
    "The toolless lid offers instant access into the enclosure. ",
    "Tilting internal antenna mount allowing up to 180 degrees of rotation. ",
    "The mounting feet can be installed on either side of the enclosure’s base ",
    "Toolless internal brackets ",
  ],
  keyFeatures:
    "Ideal for mounting access points and/or antennas in indoor environments. ",
  imgArr: [IP231504_1, IP231504_2, IP231504_3, IP231504_4, IP231504_5],
};

const IP2 = {
  partNum: "IP141205",
  dimensions: '14" x 12" x 05"',
  title: "General Purpose Enclosure",
  showcaseTitle: "14” x 12” x 5” LIGHT DUTY INDOOR ENCLOSURE",
  img: IP141205_1,
  pdf: IP141205PDF,
  installGuide: ip141205InstallGuide,
  description:
    "Access points and antennas often need to be mounted in locations that warrant the use of a protective enclosure. The 14” x 12” x 5” Light Duty Indoor Enclosure offers the installer time-saving features and provides intelligent solutions to your protection, coverage, and aesthetic concerns. This enclosure supplies additional protection from dust, moisture, and everyday wear. The IP141205 has been meticulously crafted to align with standard WiFi access point and antenna specifications, providing a range of customizable settings to cater to your specific requirements. It comes equipped for effortless installation, whether you choose to mount it horizontally or vertically on a wall or ceiling.. All AmpThink Light Duty Enclosures utilize our patented folding frame technology featuring dual weather seals, tool-less bracket installation, and modular mounting options.",
  protect:
    "The IP141205 enclosure is made with impact and UV resistant ABS/ASA plastic, capable of withstanding any potential kicks or drops. The patented folded-frame gasket design and overlapping lid offer additional protection from dust, moisture, and UV rays. The thermoformed ASA/ABS housing and patented dual weather seal provide confidence for the IT professional that their equipment will last.",
  simplicity:
    "Leveraging years of experience and product evolution, the IP141205 enclosure is designed for installers by installers. Our enclosure eliminates common issues faced during installation. Our uniquely designed internal brackets simply snap into the enclosure, no tools required. Our snap on lid provides instant access into the enclosure during install or future tuning/survey work. Our external mounting feet can be positioned on either side of the enclosure, simplifying installation in tight spaces. Featuring an optional tilting external mount, the installer is provided an additional 180 degrees of antenna pitch as needed for their application. This enclosure will protect your equipment and save installers time in the field.",
  technicalSpecs: [
    'Outside Dimensions: 13.96" x 12.03" x 4.83',
    'Inside Dimensions: 11.5" x 9.6" x 4.6"',
    "Weight: 4lbs fully assembled",
    "Ambient Temperature Range of Plastic: -4 degrees F to 176 degrees F",
  ],
  kitIncludes: [
    "Enclosure Base with patented AmpThink Folded Frame ",
    "Static Mounting Feet ",
    "Tool-less bracket for access point ",
    "Tether accessory for snap-on lid ",
  ],
  kitOptions: [
    "Tilting external mounting bracket for additional antenna pitch ",
    "UL Listed, flame retardant plastic ",
  ],
  features: [
    "Patented folded gasket frame, providing additional protection from dust, moisture, and wear.",
    "Available in both fire-retardant UL listed UL-945VA plastic or general-purpose UV and impact resistant, RF transparent ASA/ABS plastic. ",
    "Ideal for mounting access points and/or antennas in indoor environments. ",
    "Designed around a range of manufacturers including Cisco, Aruba, and Extreme.",
    "The toolless lid offers instant access into the enclosure. ",
    "Available with tilting external mounting feet allowing up to 180 degrees of rotation. ",
    "The mounting feet can be installed on either side of the enclosure’s base ",
    "Toolless internal brackets ",
  ],
  keyFeatures:
    "Ideal for mounting access points and/or antennas in indoor environments. ",
  imgArr: [IP141205_1, IP141205_2, IP141205_3],
};

const IP3 = {
  partNum: "IP141204",
  dimensions: '14" x 12" x 04"',
  title: "General Purpose Enclosure",
  showcaseTitle: "14” x 12” x 4” LIGHT DUTY INDOOR ENCLOSURE",
  img: IP141204_2,
  pdf: IP141204PDF,
  installGuide: ip141204InstallGuide,
  description:
    "Access points and antennas often need to be mounted in locations that warrant the use of a protective enclosure. The 14” x 12” x 4” Light Duty Indoor Enclosure offers the installer time-saving features and provides intelligent solutions to your protection, coverage, and aesthetic concerns. This enclosure supplies additional protection from dust, moisture, and everyday wear. The IP141204 has been meticulously crafted to align with standard WiFi access point and antenna specifications, providing a range of customizable settings to cater to your specific requirements. It comes equipped for effortless installation, whether you choose to mount it horizontally or vertically on a wall or ceiling.. All AmpThink Light Duty Enclosures utilize our patented folding frame technology featuring dual weather seals, tool-less bracket installation, and modular mounting options.",
  protect:
    "The IP141204 enclosure is made with impact and UV resistant ABS/ASA plastic, capable of withstanding any potential kicks or drops. The patented folded-frame gasket design and overlapping lid offer additional protection from dust, moisture, and UV rays. The thermoformed ASA/ABS housing and patented dual weather seal provide confidence for the IT professional that their equipment will last.",
  simplicity:
    "Leveraging years of experience and product evolution, the IP141204 enclosure is designed for installers by installers. Our enclosure eliminates common issues faced during installation. Our uniquely designed internal bracket simply snaps into the enclosure, no tools required. Our snap on lid provides instant access into the enclosure during install or future tuning/survey work. Our external mounting feet can be positioned on either side of the enclosure, simplifying installation in tight spaces. Featuring an optional tilting external mount, the installer is provided an additional 180 degrees of antenna pitch as needed for their application. This enclosure will protect your equipment and save installers time in the field.",
  technicalSpecs: [
    'Outside Dimensions: 13.96" x 12.03" x 3.54"',
    'Inside Dimensions: 11.5" x 9.6" x 3.3"',
    "Weight: 4.bs fully assembled",
    "Ambient Temperature Range of Plastic: -4 degrees F to 176 degrees F",
  ],
  kitIncludes: [
    "Enclosure Base with patented AmpThink Folded Frame",
    "Static Mounting Feet  ",
    "Tool-less bracket for access point and antenna  ",
    "Tether accessory for snap-on lid ",
  ],
  kitOptions: [
    "Tilting external mounting bracket for additional antenna pitch ",
    "UL Listed, flame retardant plastic",
  ],
  features: [
    "Patented folded gasket frame, providing additional protection from dust, moisture, and wear. ",
    "Available in both fire-retardant UL listed UL-945VA plastic or general-purpose UV and impact resistant, RF transparent ASA/ABS plastic. ",
    "Ideal for mounting access points and/or antennas in indoor environments.",
    "Designed around a range of manufacturers including Cisco, Aruba, and Extreme. ",
    "The toolless lid offers instant access into the enclosure. ",
    "Available with tilting external mounting feet allowing up to 180 degrees of rotation. ",
    "The mounting feet can be installed on either side of the enclosure’s base",
    "Toolless internal brackets ",
  ],
  keyFeatures:
    "Ideal for mounting access points and/or antennas in indoor environments.",
  imgArr: [IP141204_2, IP141204_4, IP141204_7, IP141204_10, IP141204_1],
};

const IndoorSeries = {
  partNum: null,
  dimensions: "tba",
  title: "General Purpose Indoor Series",
  line: "GPE Indoor Series",
  img: IndoorEnclosureHeader,
  pdf: "",
  headerImage: IndoorEnclosureHeader,
  header: "General Purpose Enclosure Indoor Series",
  headerInfo:
    "Safeguarding your electronics: durability, longevity, and aesthetics combined.",
  description: "tba",
  technicalSpecs: ["tba"],
  kitIncludes: [],
  kitOptions: ["tba"],
  features: ["tba"],
  imgArr: [],
  encArr: [IP3, IP2, IP1],
  pathname: "/AMFG-Website-products-series/Indoor General Purpose Series",
};

const CT1 = {
  partNum: "CT242403",
  dimensions: '24" x 24" x 03"',
  title: "Ceiling Tile Enclosure",
  showcaseTitle: "",
  img: ImgPlaceholder,
  pdf: "",
  description: "tba",
  technicalSpecs: ["tba"],
  kitIncludes: [],
  kitOptions: ["tba"],
  features: ["tba"],
  imgArr: [ImgPlaceholder, ImgPlaceholder, ImgPlaceholder],
};

const CT2 = {
  partNum: "CT242405",
  dimensions: '24" x 24" x 05"',
  title: "Ceiling Tile Enclosure",
  showcaseTitle: "",
  img: ImgPlaceholder,
  pdf: "",
  description: "tba",
  technicalSpecs: ["tba"],
  kitIncludes: [],
  kitOptions: ["tba"],
  features: ["tba"],
  imgArr: [ImgPlaceholder, ImgPlaceholder, ImgPlaceholder],
};

const CeilingTileSeries = {
  partNum: null,
  dimensions: "tba",
  title: "Ceiling Tile Series",
  line: "Ceiling Tile Series",
  img: ImgPlaceholder,
  pdf: "",
  description: "tba",
  technicalSpecs: ["tba"],
  kitIncludes: [],
  kitOptions: ["tba"],
  features: ["tba"],
  imgArr: [],
  encArr: [CT1, CT2],
  pathname: "/AMFG-Website-products-series/Ceiling Tile Series",
};

const HardCeilingEnclosure = {
  partNum: "ST151504",
  dimensions: '15" x 15" x 04"',
  title: "Hard Ceiling Enclosure",
  img: ImgPlaceholder,
  pdf: "",
  description: "tba",
  technicalSpecs: ["tba"],
  kitIncludes: [],
  kitOptions: ["tba"],
  features: ["tba"],
  imgArr: [],
  pathname: "/AMFG-Website-products-single/ST151504",
};

const UnderSeatEnclosure = {
  id: 6,
  partNum: "US131008",
  dimensions: "13” x 10” x 8”",
  title: "Under Seat Enclosure",
  img: UnderSeat5,
  pdf: pdf6,
  description:
    "Electronics (access points, antennas, radios, etc.) often need to be mounted in challenging locations where a protective enclosure is needed. All enclosures in AmpThink's General Purpose Enclosure (“GPE”) line are designed to the exact specifications of network integration professionals and are proven to protect your equipment while streamlining the installation process. Our patented folded frame, tool-less internal brackets, and modular mounting options are a few reasons our enclosures are the go-to option for the most impressive sites around the world. Our US130907 is designed specifically for installing electronic equipment under seats in outdoor public venues. The enclosure is designed to fit discreetly under event-goer’s chairs without interfering with their experience. Our enclosures are UV-resistant, power-washable, dust and kick-proof. ",
  technicalSpecs: [
    "Outside Dimensions: 12.34” x 9.44” x 7.35”",
    "Inside Dimensions: Pending Confirmation",
    "Weight: 4 lbs fully assembled",
  ],
  kitIncludes: [
    "Enclosure Base with patented AmpThink Folded Frame",
    "Mounting Feet ",
    "Tool-less bracket for access point ",
    "12 (insert size) torx lid screws",
  ],
  simplicity: null,
  kitOptions: [],
  features: [
    "Constructed from impact resistant, RF transparent ABS plastic.",
    "Available in both fire-retardant UL listed UL-945VA ABS Plastic or general-purpose UV resistant ABS.",
    "Ideal for mounting access points and/or antennas in challenging outdoor environments including stadiums, amphitheaters, and warehouses.",
    "Designed to protect access points from a range of manufacturers including Cisco, Aruba, and Extreme.",
    "Lid is secured with tamper resistant Torx screws.",
    "Perfect for mounting both an access point under a seat.",
    "Features an overlapping lid which protects the gasket from high pressure water (pressure washing or wind driven rain) to ensure dry electronics even in the harshest environments",
    "When the UL listed plastic is selected, the enclosure is a listed assembly under UL File E527722",
  ],
  kitIncludesData: [
    { img: ImgPlaceholder, title: "Under Seat Enclosure" },
    { img: ImgPlaceholder, title: "tba" },
  ],
  imgArr: [UnderSeat5, UnderSeat1, UnderSeat2, UnderSeat3, UnderSeat4],
  pathname: "/AMFG-Website-products-single/US131008",
};

const QuadGland = {
  id: 10,
  partNum: "AC001",
  dimensions: "tba",
  title: "Quad Antenna Cable Gland",
  img: QuadGland1,
  pdf: AC001PDF,
  description:
    "The Quad Antenna Cable Gland is a specialized enclosure accessory designed to provide secure and weatherproof cable management for wireless equipment enclosures. With its innovative quad-channel design, this cable gland can accommodate up to four antenna cables, ensuring efficient connectivity while maintaining the enclosure's integrity. Crafted from durable, UV-resistant materials, it offers exceptional protection against environmental factors such as moisture, dust, and temperature fluctuations. Easy to install and adaptable to various enclosure sizes, the Quad Antenna Cable Gland guarantees reliable performance and aesthetics for wireless setups in outdoor and challenging environments. Whether for telecommunications, surveillance, or industrial applications, this cable gland is an essential solution to safeguard your wireless equipment and maintain optimal signal strength.",
  technicalSpecs: [
    "Dimensions: 1.375” W – 1.34” L",
    "Compatible Cable Diameter: Ranging from .160” to .188”",
  ],
  kitIncludes: [],
  kitOptions: [],
  features: [],
  kitIncludesData: [
    { img: QuadGland1, title: "Quad Antenna Cable Gland" },
    { img: ImgPlaceholder, title: "tba" },
  ],
  imgArr: [
    QuadGland1,
    QuadGland2,
    QuadGland3,
    QuadGland4,
    QuadGland5,
    QuadGland6,
  ],
  pathname: "/AMFG-Website-products-single/MP000116",
};

const Brackets = {
  partNum: "tba",
  dimensions: "tba",
  title: "Brackets",
  img: Bracket1,
  pdf: "",
  description: "tba",
  technicalSpecs: ["tba"],
  kitIncludes: [],
  kitOptions: ["tba"],
  features: ["tba"],
  imgArr: [Bracket1, Bracket2, Bracket3, Bracket4, Bracket5],
  pathname: "/AMFG-Website-products-single/tba",
};

// const enclosureFive = {
//   id: 5,
//   partNum: "GP231504",
//   dimensions: "23” x 14”",
//   title: "General Purpose Enclosure",
//   img: ImgPlaceholder,
//   pdf: pdf5,
//   description:
//     "Electronics – access points, antennas, radios, etc.) often need to be mounted in challenging environments where environmental conditions and/or security needs dictate the use of a protective enclosure. All the enclosures in AmpThink’s General Purpose Enclosure (“GPE”) line are suitable for protecting your equipment from both the weather and tamper. Designed for a slim “side-by-side” configuration, the 23” x 15” box was designed around common WiFi access point/antenna dimensions and can be optioned with a range of internal brackets to meet your specific needs. All AmpThink GPEs are designed around AmpThink’s patented folding frame technology featuring dual weather seals, tool-less bracket installation, and modular mounting options. ",
//   technicalSpecs: [
//     "Outside Dimensions: 23.07 x 14.39” x 4.05”",
//     "Internal Dimensions: 20.8” x 12.1” x 3.8”",
//     "Weight: 5.6 lbs fully assembled",
//   ],
//   kitIncludes: [
//     "Enclosure Base with patented AmpThink Folded Frame",
//     "Mounting Feet (static or tilting)",
//     "Tool-less bracket for access point ",
//     "12 (insert size) torx lid screws",
//   ],
//   kitOptions: [
//     "Tilting Antenna mounting bracket for mounting external antennas",
//     "UL Listed plastic",
//   ],
//   features: [
//     "Constructed from impact resistant, RF transparent ABS plastic.",
//     "Available in both fire-retardant UL listed UL-945VA ABS Plastic or general-purpose UV resistant ABS.",
//     "Ideal for mounting access points and/or antennas in challenging outdoor environments including stadiums and warehouses.",
//     "Designed to protect access points from a range of manufacturers including Cisco, Aruba, and Extreme.",
//     "Lid is secured with tamper resistant Torx screws.",
//     "Perfect for mounting both an access point and external antenna side-by-side in the same enclosure.",
//     "Cisco’s AIR ANT2566PW4-R and Aruba’s AP-ANT-45.",
//     "Available with a tilting internal antenna mount allowing up to 180 degrees of rotation.",
//     "Features an overlapping lid which protects the gasket from high pressure water (pressure washing or wind driven rain) to ensure dry electronics even in the harshest environments",
//     "When the UL listed plastic is selected, the enclosure is a listed assembly under UL File E527722 ",
//   ],
//   imgArr: [ImgPlaceholder, ImgPlaceholder, ImgPlaceholder, ImgPlaceholder],
// };

// const ShortStaticFeet = {
//   id: 11,
//   partNum: "GP141204",
//   dimensions: "14” x 12”",
//   title: "Short Lid with Static Feet",
//   img: ShortStaticFeet1,
//   pdf: pdf1,
//   description:
//     "Electronics – access points, antennas, radios, etc.) often need to be mounted in challenging environments where environmental conditions and/or security needs dictate the use of a protective enclosure. All of the enclosures in AmpThink General PurposeEnclosure (“GPE”) line are suitable for protecting your equipment from both the weather and tamper. The 14”x12” box was designed around common WiFi access point dimensions and can be optioned with a range of internal brackets and mounting feet to meet your specific needs. All AmpThink GPEs are designed around AmpThink patented folding frame technology featuring dual weather seals, tool-less bracket installation, and modular mounting options.",
//   technicalSpecs: [
//     "Outside Dimensions: 13.96” x 12.03” x 4.83”",
//     "Inside Dimensions: 11.5” x 9.6” x 4.6”",
//     "Weight: 4 lbs fully assembled",
//   ],
//   kitIncludes: [
//     "Enclosure Base with patented AmpThink Folded Frame",
//     "Mounting Feet (static or tilting)",
//     "Tool-less bracket for access point mounting",
//     "12 (insert size) torx lid screws",
//   ],
//   kitOptions: [
//     "• Static mounting feet",
//     "• Tiltable mounting feet capable of 180 degrees of rotation",
//   ],
//   features: [
//     "Constructed from impact resistant, RF transparent ABS plastic.",
//     "Available in both fire-retardant UL listed UL-945VA ABS Plastic or general-purpose UV resistant ABS.",
//     "Ideal for mounting access points in challenging outdoor environments including stadiums and warehouses.",
//     "Designed to protect access points from a range of manufacturers including Cisco, Aruba, and Extreme.",
//     "Lid is secured with tamper resistant Torx screws.",
//     "Perfect for access points with integrated antennas.",
//     "Cisco’s AIR ANT2566PW4-R and Aruba’s AP-ANT-45.",
//     "Available with static mounting feet or tilting feet allowing up to 180 degrees of rotation.",
//     "The enclosure features an overlapping lid which protects the gasket from high pressure water (pressure washing or wind driven rain) to ensure dry electronics even in the harshest environments",
//     "When the UL listed plastic is selected, the enclosure is a listed assembly under UL File E527722",
//   ],
//   imgArr: [
//     ShortStaticFeet1,
//     ShortStaticFeet2,
//     ShortStaticFeet3,
//     ShortStaticFeet4,
//     ShortStaticFeet5,
//     ShortStaticFeet6,
//   ],
// };

// const ShortSwivelFeet = {
//   id: 12,
//   partNum: "GP141204",
//   dimensions: "14” x 12”",
//   title: "Short Lid with Swivel Feet",
//   img: ShortSwivelFeet1,
//   pdf: pdf1,
//   description:
//     "Electronics – access points, antennas, radios, etc.) often need to be mounted in challenging environments where environmental conditions and/or security needs dictate the use of a protective enclosure. All of the enclosures in AmpThink General PurposeEnclosure (“GPE”) line are suitable for protecting your equipment from both the weather and tamper. The 14”x12” box was designed around common WiFi access point dimensions and can be optioned with a range of internal brackets and mounting feet to meet your specific needs. All AmpThink GPEs are designed around AmpThink patented folding frame technology featuring dual weather seals, tool-less bracket installation, and modular mounting options.",
//   technicalSpecs: [
//     "Outside Dimensions: 13.96” x 12.03” x 4.83”",
//     "Inside Dimensions: 11.5” x 9.6” x 4.6”",
//     "Weight: 4 lbs fully assembled",
//   ],
//   kitIncludes: [
//     "Enclosure Base with patented AmpThink Folded Frame",
//     "Mounting Feet (static or tilting)",
//     "Tool-less bracket for access point mounting",
//     "12 (insert size) torx lid screws",
//   ],
//   kitOptions: [
//     "• Static mounting feet",
//     "• Tiltable mounting feet capable of 180 degrees of rotation",
//   ],
//   features: [
//     "Constructed from impact resistant, RF transparent ABS plastic.",
//     "Available in both fire-retardant UL listed UL-945VA ABS Plastic or general-purpose UV resistant ABS.",
//     "Ideal for mounting access points in challenging outdoor environments including stadiums and warehouses.",
//     "Designed to protect access points from a range of manufacturers including Cisco, Aruba, and Extreme.",
//     "Lid is secured with tamper resistant Torx screws.",
//     "Perfect for access points with integrated antennas.",
//     "Cisco’s AIR ANT2566PW4-R and Aruba’s AP-ANT-45.",
//     "Available with static mounting feet or tilting feet allowing up to 180 degrees of rotation.",
//     "The enclosure features an overlapping lid which protects the gasket from high pressure water (pressure washing or wind driven rain) to ensure dry electronics even in the harshest environments",
//     "When the UL listed plastic is selected, the enclosure is a listed assembly under UL File E527722",
//   ],
//   imgArr: [ShortSwivelFeet1, ShortSwivelFeet2, ShortSwivelFeet3],
// };

// const Snap = {
//   id: 13,
//   partNum: "IP141204",
//   dimensions: "14” x 12” x 4”",
//   title: "General Purpose Indoor Enclosure",
//   img: Snap2,
//   pdf: "",
//   description:
//     "Access points and antennas often need to be mounted in locations that warrant the use of a protective enclosure. The 14” x 12” x 4” Light Duty Indoor Enclosure offers the installer time-saving features and provides intelligent solutions to your protection, coverage, and aesthetic concerns. This enclosure supplies additional protection from dust, moisture, and everyday wear. The IP141204 was designed around common WiFi access point and antenna dimensions and offers multiple configuration options to meet your needs. This enclosure is best suited for mounting a single AP on a wall, fence, or on the floor. All AmpThink Light Duty Enclosures utilize our patented folding frame technology featuring dual weather seals, tool-less bracket installation, and modular mounting options.",
//   technicalSpecs: ["tba"],
//   kitIncludes: [
//     "Enclosure Base with patented AmpThink Folded Frame",
//     "Static Mounting Feet ",
//     "Tool-less bracket for access point and antenna",
//     "Tether accessory for snap-on lid",
//   ],
//   kitOptions: [
//     "Tilting external mounting bracket for additional antenna pitch ",
//     "UL Listed, flame retardant plastic ",
//   ],
//   features: [
//     "Patented folded gasket frame, providing additional protection from dust, moisture, and wear. ",
//     "Available in both fire-retardant UL listed UL-945VA plastic or general-purpose UV and impact resistant, RF transparent ASA/ABS plastic. ",
//     "Ideal for mounting access points and/or antennas in indoor environments. ",
//     "Designed around a range of manufacturers including Cisco, Aruba, and Extreme.",
//     "The toolless lid offers instant access into the enclosure.",
//     "Available with tilting external mounting feet allowing up to 180 degrees of rotation. ",
//     "The mounting feet can be installed on either side of the enclosure’s base ",
//     "Toolless internal brackets",
//   ],
//   imgArr: [
//     Snap1,
//     Snap2,
//     Snap3,
//     Snap4,
//     Snap5,
//     Snap6,
//     Snap7,
//     Snap8,
//     Snap9,
//     Snap10,
//     Snap11,
//   ],
// };

// const TallStaticFeet = {
//   id: 14,
//   partNum: "GP141205",
//   dimensions: "14” x 12”",
//   title: "Tall Lid with Static Feet",
//   img: TallStaticFeet1,
//   pdf: pdf2,
//   description:
//     "Electronics – access points, antennas, radios, etc.) often need to be mounted in challenging environments where environmental conditions and/or security needs dictate the use of a protective enclosure. All of the enclosures in AmpThink General PurposeEnclosure (“GPE”) line are suitable for protecting your equipment from both the weather and tamper. The 14”x12” box was designed around common WiFi access point dimensions and can be optioned with a range of internal brackets and mounting feet to meet your specific needs. All AmpThink GPEs are designed around AmpThink patented folding frame technology featuring dual weather seals, tool-less bracket installation, and modular mounting options.",
//   technicalSpecs: [
//     "Outside Dimensions: 13.96” x 12.03” x 4.83”",
//     "Inside Dimensions: 11.5” x 9.6” x 4.6”",
//     "Weight: 4 lbs fully assembled",
//   ],
//   kitIncludes: [
//     "Enclosure Base with patented AmpThink Folded Frame",
//     "Mounting Feet (static or tilting)",
//     "Tool-less bracket for access point or antenna mounting",
//     "12 (insert size) torx lid screws",
//   ],
//   kitOptions: [
//     "Antenna mounting bracket for mounting external antennas",
//     "Static mounting feet",
//     "Tiltable mounting feet capable of 180 degrees of rotation.",
//   ],
//   features: [
//     "Constructed from impact resistant, RF transparent ABS plastic.",
//     "Available in both fire-retardant UL listed UL-945VA ABS Plastic or general-purpose UV resistant ABS.",
//     "Ideal for mounting access points and/or antennas in challenging outdoor environments including stadiums and warehouses.",
//     "Designed to protect access points from a range of manufacturers including Cisco, Aruba, and Extreme.",
//     "Lid is secured with tamper resistant Torx screws.",
//     "Perfect for mounting both an access point and external antenna in the same enclosure.",
//     "Cisco’s AIR ANT2566PW4-R and Aruba’s AP-ANT-45.",
//     "Available with static mounting feet or tilting feet allowing up to 180 degrees of rotation.",
//     "The enclosure features an overlapping lid which protects the gasket from high pressure water (pressure washing or wind driven rain) to ensure dry electronics even in the harshest environments",
//     "When the UL listed plastic is selected, the enclosure is a listed assembly under UL File E527722",
//   ],
//   imgArr: [
//     TallStaticFeet1,
//     TallStaticFeet2,
//     TallStaticFeet3,
//     TallStaticFeet4,
//     TallStaticFeet5,
//     TallStaticFeet6,
//     TallStaticFeet7,
//     TallStaticFeet8,
//   ],
// };

// const TallSwivelFeet = {
//   id: 15,
//   partNum: "GP141205",
//   dimensions: "14” x 12”",
//   title: "Tall Lid with Swivel Feet",
//   img: TallSwivelFeet1,
//   pdf: pdf2,
//   description:
//     "Electronics – access points, antennas, radios, etc.) often need to be mounted in challenging environments where environmental conditions and/or security needs dictate the use of a protective enclosure. All of the enclosures in AmpThink General PurposeEnclosure (“GPE”) line are suitable for protecting your equipment from both the weather and tamper. The 14”x12” box was designed around common WiFi access point dimensions and can be optioned with a range of internal brackets and mounting feet to meet your specific needs. All AmpThink GPEs are designed around AmpThink patented folding frame technology featuring dual weather seals, tool-less bracket installation, and modular mounting options.",
//   technicalSpecs: [
//     "Outside Dimensions: 13.96” x 12.03” x 4.83”",
//     "Inside Dimensions: 11.5” x 9.6” x 4.6”",
//     "Weight: 4 lbs fully assembled",
//   ],
//   kitIncludes: [
//     "Enclosure Base with patented AmpThink Folded Frame",
//     "Mounting Feet (static or tilting)",
//     "Tool-less bracket for access point or antenna mounting",
//     "12 (insert size) torx lid screws",
//   ],
//   kitOptions: [
//     "Antenna mounting bracket for mounting external antennas",
//     "Static mounting feet",
//     "Tiltable mounting feet capable of 180 degrees of rotation.",
//   ],
//   features: [
//     "Constructed from impact resistant, RF transparent ABS plastic.",
//     "Available in both fire-retardant UL listed UL-945VA ABS Plastic or general-purpose UV resistant ABS.",
//     "Ideal for mounting access points and/or antennas in challenging outdoor environments including stadiums and warehouses.",
//     "Designed to protect access points from a range of manufacturers including Cisco, Aruba, and Extreme.",
//     "Lid is secured with tamper resistant Torx screws.",
//     "Perfect for mounting both an access point and external antenna in the same enclosure.",
//     "Cisco’s AIR ANT2566PW4-R and Aruba’s AP-ANT-45.",
//     "Available with static mounting feet or tilting feet allowing up to 180 degrees of rotation.",
//     "The enclosure features an overlapping lid which protects the gasket from high pressure water (pressure washing or wind driven rain) to ensure dry electronics even in the harshest environments",
//     "When the UL listed plastic is selected, the enclosure is a listed assembly under UL File E527722",
//   ],
//   imgArr: [TallSwivelFeet1, TallSwivelFeet2, TallSwivelFeet3],
// };

const EnclosureData = [
  OutdoorSeries,
  IndoorSeries,
  // CeilingTileSeries,
  // HardCeilingEnclosure,
  UnderSeatEnclosure,
  QuadGland,
];

export default EnclosureData;
