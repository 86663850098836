import { Link } from "react-router-dom";
import Collection from "../../components/collection/Collection";
import CarouselOne from "../../components/carousel-1/CarouselOne";
import CarouselTwo from "../../components/carousel-2/CarouselTwo";

import BrandingIcon from "../../assets/imgs/icons/icon-branding.png";
import MountingIcon from "../../assets/imgs/icons/icon-mounting.png";
import SolutionIcon from "../../assets/imgs/icons/icon-solutions.png";

import NewInteractable from "../../components/new-interactable/NewInteractable";

import "../../assets/styles/landing.css";
import "../../assets/styles/about-original.css";

const EnclosureLanding = () => {
  return (
    <div
      style={{ backgroundColor: "var(--black)" }}
      className="enclosure-landing-container"
    >
      <section id="hero" className="content-container">
        <CarouselTwo />
        {/* <div className="hero-gradient"></div> */}
        <h1>BY INSTALLERS FOR INSTALLERS</h1>
      </section>

      <section id="about" className="content-container">
        <p>
          AmpThink enclosures are the ultimate choice for durability,
          construction quality, and flexibility in the market. Our product line
          has evolved from the industry's first patented handrail enclosures
          introduced in 2012, and we have continued to innovate since. Our
          solutions are the perfect answer to integrate wireless equipment into
          indoor and outdoor environments with both functionality and aesthetics
          in mind.
        </p>
        <CarouselOne />
        <p>
          Today, our enclosures are the go-to solution in stadiums and arenas,
          where tens-of-thousands of our handrail, under seat, wall mountable,
          and ceiling mountable systems can be found.
        </p>
      </section>

      <section id="enclosure-interactable-section">
        <NewInteractable />
      </section>

      <section className="enclosure-about-section">
        <h2>We're Not One Size Fits All</h2>
        <div className="enclosure-about-section-card-container">
          <div className="enclosure-about-card">
            <img src={BrandingIcon} alt="" />
            <div>
              <h3>Custom Branding</h3>
              <p>
                Do you need a dozen custom handrails? Do you want a logo
                debossed in them? We can do that for the same price as the guys
                who are selling one size fits all knock-offs manufactured in
                China.
              </p>
            </div>
          </div>
          <div className="enclosure-about-card">
            <img src={SolutionIcon} alt="" />
            <div>
              <h3>Unique Solutions</h3>
              <p>
                Do you need a solution for weatherizing the attachment to a
                building or the conduit entry? We've done that before.
              </p>
            </div>
          </div>
          <div className="enclosure-about-card">
            <img src={MountingIcon} alt="" />
            <div>
              <h3>Mounting Options</h3>
              <p>
                Is your mounting condition unique? We can customize the feet for
                your application.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section id="collection">
        <Collection />
      </section>
      <div
        className="contact-component"
        style={{ color: "#fff", backgroundColor: "var(--black)" }}
      >
        <h3 style={{ color: "#fff" }}>Ready to get started?</h3>
        <p style={{ marginBottom: "20px" }}>
          Our team is here to help and answer any questions you may have.
        </p>
        <Link to="/contact" className="contact-btn">
          CONTACT OUR TEAM TODAY
        </Link>
      </div>
    </div>
  );
};

export default EnclosureLanding;
