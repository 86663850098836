import React from "react";

//components
import ManufacturingCard from "../../components/manufacturing-card/ManufacturingCard";
import OurEquipment from "../../components/equipment/OurEquipment";
import LinkComponent from "../../components/link-section/LinkComponent";
import ContactComponent from "../../components/contact/ContactComponent";

import HeroImg from "../../assets/imgs/hero-imgs/IMG_0850.JPG";
import CardOne from "../../assets/imgs/card-images/equipment-card-1.png";
import CardTwo from "../../assets/imgs/card-images/equipment-card-2.png";

import "../../assets/styles/template.css";
import "../../assets/styles/template-mobile.css";

const EquipmentPage = () => {
  const cardData = [
    {
      img: CardOne,
      title: "Innovative",
      text: "We continuously invest in new technologies and equipment to stay ahead of the curve and provide our customers with the best possible solutions. Trust us to provide the highest quality products using the latest and most advanced equipment available.",
      orientation: false,
    },
    {
      img: CardTwo,
      title: "Expertise",
      text: "From CNC machines to thermoforming equipment, we have the tools and expertise to tackle even the most complex manufacturing challenges.",
      orientation: true,
    },
  ];

  return (
    <div className="page-container">
      <section
        className="hero-section"
        style={{ backgroundImage: `url(${HeroImg})` }}
      >
        <div className="hero-shader"></div>
        <div className="hero-header">
          <h1>EQUIPMENT</h1>
          <h4>State-of-the-art and optimized for precision manufacturing</h4>
        </div>
      </section>
      <section className="intro-section">
        <p>
          We employ cutting-edge machinery and rigorous control to uphold the
          highest quality and precision standards. Our manufacturing processes
          are carefully controlled to minimize waste and maximize efficiency,
          allowing us to deliver products that meet your exact specifications
          with fast turnaround times.
        </p>
      </section>
      <section className="card-container">
        <h2>HOW WE'RE DIFFERENT</h2>
        {cardData.map((card, key) => {
          return (
            <ManufacturingCard
              key={key}
              img={card.img}
              title={card.title}
              text={card.text}
              orientation={card.orientation}
            />
          );
        })}
      </section>
      <section className="info-section">
        <OurEquipment />
      </section>
      <section>
        <LinkComponent />
      </section>
      <section>
        <ContactComponent />
      </section>
    </div>
  );
};

export default EquipmentPage;
