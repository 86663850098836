import React, { useState, useRef } from "react";

import ExteriorInteractable from "./ExteriorInteractable";
import InteriorInteractable from "./InteriorInteractable";

import "./new-interactable.css";

const NewInteractable = () => {
  const [interactable, setInteractable] = useState(true);

  const exteriorBtn = useRef(null);
  const interiorBtn = useRef(null);

  const handleChangeImg = (img) => {
    if (img === "exterior") {
      setInteractable(true);
      exteriorBtn.current.classList.add("active-interactable-btn");
      interiorBtn.current.classList.remove("active-interactable-btn");
    } else if (img === "interior") {
      setInteractable(false);
      exteriorBtn.current.classList.remove("active-interactable-btn");
      interiorBtn.current.classList.add("active-interactable-btn");
    }
  };

  return (
    <>
      <div>
        <h2>It's the Little Things That Matter</h2>
        <p style={{ maxWidth: "850px", margin: "auto", fontSize: "26px" }}>
          Not all of the features of our enclosures are immediately obvious.
          Here are a few items that you may have overlooked
        </p>
      </div>
      <div style={{ paddingTop: "100px" }}>
        <button
          ref={exteriorBtn}
          onClick={() => handleChangeImg("exterior")}
          className="interactable-btn active-interactable-btn"
        >
          EXTERIOR
        </button>
        <button
          ref={interiorBtn}
          onClick={() => handleChangeImg("interior")}
          className="interactable-btn"
        >
          INTERIOR
        </button>
        <hr className="new-interactable-hr" />
        {interactable ? <ExteriorInteractable /> : <InteriorInteractable />}
      </div>
    </>
  );
};

export default NewInteractable;
