import React, { useState } from "react";
import { Formik } from "formik";
import { motion } from "framer-motion";
import FormError from "./FormError";
import axios from "axios";

import "./ampthink-contact.css";

const contactUrl =
  " https://un77id2yqg.execute-api.us-east-2.amazonaws.com/ampthink-manufacturing-mail";
const postOptions = {
  contentType: "application/json",
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "origin",
  },
};

export default function ContactForm() {
  const [sendStatus, setSendStatus] = useState("SEND");
  const [customValidation, setCustomValidation] = useState(false);
  const [buttonColor, setButtonColor] = useState("#aaa");

  const buttonVariants = {
    hovered: {
      color: "#fff",
      background: "#ff5722",
      cursor: "pointer",
    },
    static: {
      background: "rgba(0,0,0,0)",
    },
    tapped: {
      borderRadius: "30px",
      scale: 0.9,
    },
  };

  const handleValidate = (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = "Required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = "Invalid email address";
    }

    if (!values.name) {
      errors.name = "Required";
    }

    if (!values.email) {
      errors.email = "Required";
    }

    if (!values.message) {
      errors.message = "Required";
    }
    if (values.name && values.email && values.message) {
      setCustomValidation(true);
      setButtonColor("#ff5722");
    }

    return errors;
  };

  const handleSubmit = (values) => {
    setSendStatus("SENDING");
    axios
      .post(contactUrl, values, postOptions)
      .then((res) => {
        if (res.status === 200) {
          setSendStatus("SENT");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  if (sendStatus !== "SENT") {
    return (
      <Formik
        initialValues={{ name: "", email: "", phone: "", org: "", message: "" }}
        validate={handleValidate}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            handleSubmit(values);
            setSubmitting(false);
          }, 400);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              name="name"
              placeholder="Name*"
              required
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
            />

            <FormError
              show={errors.name && touched.name}
              message={errors.name}
            />

            <input
              type="email"
              name="email"
              placeholder="Email*"
              required
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
            />

            <FormError
              show={errors.email && touched.email}
              message={errors.email}
            />

            <input
              type="text"
              name="org"
              placeholder="Organization"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.org}
            />

            <FormError show={errors.org && touched.org} message={errors.org} />

            <input
              type="tel"
              name="phone"
              placeholder="Phone"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.phone}
            />

            <FormError
              show={errors.phone && touched.phone}
              message={errors.phone}
            />

            <textarea
              type="textarea"
              name="message"
              placeholder="Your message*"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.message}
            />

            <FormError
              show={errors.message && touched.message}
              message={errors.message}
            />

            <div className="contact-form-button-wrapper">
              <motion.button
                style={
                  customValidation && {
                    borderColor: buttonColor,
                    color: buttonColor,
                  }
                }
                variants={buttonVariants}
                whileHover="hovered"
                whileTap="tapped"
                initial="static"
                className="contact-form-button"
                type="submit"
                disabled={isSubmitting}
              >
                {sendStatus}
              </motion.button>
            </div>
          </form>
        )}
      </Formik>
    );
  } else {
    return (
      <div className="contact-thanks">
        <div className="contact-thanks-you">Thank you</div>
        We've received your request and someone will be in touch soon.
      </div>
    );
  }
}
