const OverLappingShell = {
  title: "Overlapping Shell",
  doc: "AmpThink enclosures consist of two mating shells, with one shell overlapping the frame of the other during sealing. This overlapping design provides protection to the internal gasket by preventing direct exposure to high-pressure water. This is particularly important for enclosures subject to pressure washing, as applying high-pressure water directly to the seam of a traditional enclosure can deform the gasket and allow water to seep in. With the AmpThink system, the outer shell diverts high-pressure water away from the gasket by overlapping the inner shell, ensuring a durable seal that can withstand pressure washing.",
};

const AbsPlastic = {
  title: "ABS Plastic",
  doc: "At AmpThink, we manufacture our enclosures using ABS plastic instead of polycarbonate, which is preferred by our competitors. This choice of material is crucial, as ABS offers superior durability across the range of temperatures in which our enclosure systems operate. ABS is particularly stable at high temperatures, which prevents warping and ensures a reliable seal. At the same time, ABS is less brittle at lower temperatures, providing greater resistance to shattering than polycarbonate. ",
};

const Fasteners = {
  title: "Fasteners",
  doc: "While our competitors may view the number of screws required to close our enclosures as a disadvantage, we see it as an advantage. Our fastener spacing is based on lessons learned from harsh marine environments and gasket compression studies, resulting in a uniform gasket compression that provides a reliable seal capable of withstanding large temperature swings. Additionally, our fasteners are positioned outside of the gasket, which means that the screws used to affix the lid and mounting hardware do not create additional opportunities for water ingress in the field.",
};

const MountingFeet = {
  title: "Mounting Feet",
  doc: "Our external mounting options are designed to provide maximum flexibility and coverage both indoors and outdoors. With our stainless-steel brackets, you can attach the mounting feet to all four sides, allowing you to install the enclosure in any orientation for the best Wi-Fi coverage. For added convenience, our optional hinged feet facilitate antenna tilts, ensuring that you can achieve the perfect angle to further enhance coverage. Plus, we offer pole mounting options to suit a wide range of installation scenarios.",
};

const dataArray = [AbsPlastic, OverLappingShell, Fasteners, MountingFeet];

export default { dataArray };
