//react imports
import { Routes, Route, json } from "react-router-dom";
import ScrollToTop from "./util/helpers/ScrollToTop";
import Header from "./components/nav/Header";
import Footer from "./components/footer/Footer";

import ManufacturingLanding from "./pages/manufacturing-landing/ManufacturingLanding";
import EnclosureLanding from "./pages/enclosures/EnclosureLanding";
import ContactPage from "./pages/contact/Contact";
import Enclosures from "./pages/enclosures/Enclosures";
import SingleProductPage from "./pages/single-product-page/singleProductPage";
import NotFound from "./pages/404/404";
import FabricationPage from "./pages/fabrication/FabricationPage";
import SeriesPage from "./pages/series-page/SeriesPage";
import EquipmentPage from "./pages/equipment/EquipmentPage";
import CableAssemblyPage from "./pages/cable-assembly/CableAssemblyPage";
import MetalBendingPage from "./pages/metal-bending/MetalBendingPage";
import QAPage from "./pages/qa/QAPage";
import CNCPage from "./pages/cnc/CNCPage";
import ThermoformingPage from "./pages/thermoforming/ThermoformingPage";

import "./App.css";

function App() {
  return (
    <div id="top" className="App">
      <ScrollToTop />
      <Header />
      <Routes>
        <Route path="/" element={<ManufacturingLanding />} />
        <Route path="/enclosures" element={<EnclosureLanding />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/products" element={<Enclosures />} />
        <Route path="/custom-manufacturing" element={<FabricationPage />} />
        <Route
          path="/AMFG-Website-products-single/:enclosureId/:selectedProduct/:index"
          element={<SingleProductPage />}
        />
        <Route
          path="/AMFG-Website-products-series/:enclosureId/:selectedProduct/:index"
          element={<SeriesPage />}
        />
        <Route path="/equipment" element={<EquipmentPage />} />
        <Route path="/metal-bending" element={<MetalBendingPage />} />
        <Route path="/cable-assembly" element={<CableAssemblyPage />} />
        <Route path="/quality-assurance" element={<QAPage />} />
        <Route path="/cnc" element={<CNCPage />} />
        <Route path="/thermoforming" element={<ThermoformingPage />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
