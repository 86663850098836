import React from "react";
import { Link } from "react-router-dom";
import thumbnailOne from "../../assets/imgs/thumbnails/video-thumbnail-one.png";
import thumbnailTwo from "../../assets/imgs/thumbnails/video-thumbnail-two.png";
import SlimThumbnail from "../../assets/imgs/thumbnails/thumbnail-slim profile.png";
import HandrailsThumbnail from "../../assets/imgs/thumbnails/thumbnail-handrails.png";
import UseThumbnail from "../../assets/imgs/thumbnails/thumbnail-USE.png";
import SteThumbnail from "../../assets/imgs/thumbnails/thumbnail-STE.png";
import PlayBtn from "../../assets/imgs/icons/video-play-btn.svg";

import "./collection.css";

const VideoSection = ({ setIsVideoModal, videoFunc }) => {
  const playVideo = (video) => {
    setIsVideoModal(true);
    videoFunc(video);
  };

  return (
    <div className="video-section">
      <h2>View Our Collection</h2>
      <div className="video-section-container">
        <div className="video-section-large" onClick={() => playVideo(0)}>
          <div className="video-section-thumbnail-container">
            <img
              style={{ width: "100%" }}
              className="max-width-100"
              src={thumbnailOne}
              alt="video thumbnail"
            />
            <img className="play-btn" src={PlayBtn} alt="video button" />
          </div>
          <h3>MEET THE AMPTHINK AMPBOX ENCLOSURE</h3>
        </div>
        <div className="video-section-small">
          <div className="small-video-container" onClick={() => playVideo(1)}>
            <div className="video-section-thumbnail-container">
              <img
                style={{ width: "100%" }}
                src={SlimThumbnail}
                alt="video thumbnail"
              />
              <img className="play-btn" src={PlayBtn} alt="video button" />
            </div>
            <h3>SLIM PROFILE</h3>
          </div>
          <div className="small-video-container" onClick={() => playVideo(2)}>
            <div className="video-section-thumbnail-container">
              <img
                style={{ width: "100%" }}
                src={HandrailsThumbnail}
                alt="video thumbnail"
              />
              <img className="play-btn" src={PlayBtn} alt="video button" />
            </div>
            <h3>HANDRAILS</h3>
          </div>
          <div className="small-video-container" onClick={() => playVideo(3)}>
            <div className="video-section-thumbnail-container">
              <img
                style={{ width: "100%" }}
                src={UseThumbnail}
                alt="video thumbnail"
              />
              <img className="play-btn" src={PlayBtn} alt="video button" />
            </div>
            <h3>UNDERSEAT ENCLOSURES (USE)</h3>
          </div>
          <div className="small-video-container" onClick={() => playVideo(4)}>
            <div className="video-section-thumbnail-container">
              <img
                style={{ width: "100%" }}
                src={SteThumbnail}
                alt="video thumbnail"
              />
              <img className="play-btn" src={PlayBtn} alt="video button" />
            </div>
            <h3>SINGLE TRADE ENCLOSURE (STE)</h3>
          </div>
        </div>
      </div>
      <Link to="/products" className="enclosure-landing-btn">
        see all products
      </Link>
    </div>
  );
};

export default VideoSection;
