import React from "react";

import { useNavigate, Link } from "react-router-dom";
import HeroOne from "../../assets/imgs/mfg/hero-one.png";
import HeroTwo from "../../assets/imgs/mfg/hero-two.png";
import ThumbOne from "../../assets/imgs/thumbnails/Products_thumb.jpg";
import ThumbTwo from "../../assets/imgs/thumbnails/Fabrication_thumb.jpg";
import ThumbThree from "../../assets/imgs/thumbnails/Precision_Thumb.jpg";

import "../../assets/styles/manufacturing-landing.css";

const ManufacturingLanding = () => {
  const navigate = useNavigate();

  const handleHover = (e) => {
    e.target.firstChild.style = "background-color: rgba(0, 0, 0, 0.4)";
    e.target.classList.add("active-hero");
  };

  const handleUnhover = (e) => {
    e.target.firstChild.style = "";
    e.target.classList.remove("active-hero");
  };

  const handleRouteFunc = (route) => {
    navigate(route);
  };

  return (
    <div className="manufacturing-landing-container">
      <section className="manufacturing-landing-hero-section">
        <div
          className="hero"
          style={{
            // backgroundImage: `url(${HeroOne})`,
            // backgroundSize: "cover",
            position: "relative",
            overflow: "hidden",
          }}
          onClick={() => handleRouteFunc("enclosures")}
          onMouseEnter={(e) => handleHover(e)}
          onMouseLeave={(e) => handleUnhover(e)}
        >
          <div className="layer"></div>
          <h1 style={{ pointerEvents: "none" }}>
            ENCLOSURES &<br /> PRODUCTS
          </h1>
          <h4>VIEW OUR PRODUCT LINE</h4>
          <img
            className="hero-img hero-img-left"
            src={HeroOne}
            alt="stadium preview"
          />
        </div>
        <hr className="manufacturing-landing-hero-hr" style={{ zIndex: "2" }} />
        <div
          className="hero"
          style={{
            // backgroundImage: `url(${HeroTwo})`,
            // backgroundSize: "cover",
            position: "relative",
            overflow: "hidden",
          }}
          onClick={() => handleRouteFunc("/custom-manufacturing")}
          onMouseEnter={(e) => handleHover(e)}
          onMouseLeave={(e) => handleUnhover(e)}
        >
          <div className="layer"></div>
          <h1 style={{ pointerEvents: "none" }}>
            CUSTOM
            <br /> MANUFACTURING
          </h1>
          <h4>VIEW OUR CAPABILITIES</h4>
          <img
            className="hero-img hero-img-right"
            src={HeroTwo}
            alt="stadium preview"
          />
        </div>
      </section>
      <section className="manufacturing-landing-intro-section">
        <h2>
          DELIVERING DESIGN FOR MANUFACTURING <br /> SERVICES TO COMPANIES
          AROUND THE GLOBE
        </h2>
        <p>
          Cost is driven by time. That's why we work diligently every day and
          invest every effort into bringing you the best manufactured product
          int he shortest amount of time. Using a combination of creativity and
          science delivers gets us from prototype to production fast and
          effectively.
        </p>
        <p>
          One of the key ways to reduce cost is through creatively solving
          incredibly complex problems. Working closely with our customers allows
          us to resolve issues before they get started.
        </p>
      </section>
      <section className="manufacturing-landing-info-section">
        <div>
          <h4>ENGINEERING EXPERTISE</h4>
          <p>
            We provide value added manufacturing and assembly services tailored
            to our customer's unique requirements.
          </p>
        </div>
        <div>
          <h4>CREATIVE MASTERY</h4>
          <p>
            From concept to assembly, our engineers bring your vision to life.
            With expertise in design, manufacturing, and testing, we ensure
            seamless product realization, turning ideas into reality.
          </p>
        </div>
        <div>
          <h4>ITERATIVE DESIGN CYCLE</h4>
          <p>
            We're committed to continuous improvement, driven by precision,
            innovation, and excellence. Our relentless pursuit of perfection
            fuels top-notch manufacturing solutions.
          </p>
        </div>
        <div>
          <h4>QUALITY & ASSURANCE</h4>
          <p>
            Quality is at the core of AmpThink's Manufacturing culture. We are
            ISO9001:2015 certified which serves as the foundation for our
            Quality Management System
          </p>
        </div>
      </section>
      <section className="manufacturing-card-section">
        <div className="off-grey-background"></div>
        <div className="manufacturing-card-container">
          <Link className="manufacturing-card-link" to="/enclosures">
            <div className="manufacturing-card">
              <div className="orange-top">
                <h4>CUSTOM ENCLOSURES & PRODUCTS</h4>
              </div>
              <div className="black-bottom">
                <img src={ThumbOne} alt="AMP products" />
              </div>
            </div>
          </Link>
          <Link className="manufacturing-card-link" to="/custom-manufacturing">
            <div className="manufacturing-card">
              <div className="orange-top">
                <h4>TOOL FABRICATION & DELIVERY</h4>
              </div>
              <div className="black-bottom">
                <img src={ThumbTwo} alt="AMP fabrication" />
              </div>
            </div>
          </Link>
          <Link className="manufacturing-card-link" to="/cnc">
            <div className="manufacturing-card">
              <div className="orange-top">
                <h4>PRECISIONS MACHINING SERVICES</h4>
              </div>
              <div className="black-bottom">
                <img src={ThumbThree} alt="AMP precision" />
              </div>
            </div>
          </Link>
        </div>
      </section>
      <section className="manufacturing-about-section">
        <h3>
          Since 1994, AmpThink Manufacturing has been a trusted provider of
          top-tier design, engineering, and manufacturing services. Proudly
          based in Texas and dedicated to the "Made in America" ethos, we
          consistently deliver high-quality solutions that reflect our
          commitment to excellence.
        </h3>
        <p style={{ margin: "none" }}>
          Over the years, we've transformed into the premier global destination
          for all your manufacturing and product development requirements. Our
          extensive network of customers spans the globe. We operate more than
          20 state-of-the-art CNC machining and lathe centers, with dedicated
          teams on both A and B shifts, ensuring we can meet your delivery
          deadlines with precision and reliability.
        </p>
      </section>
    </div>
  );
};

export default ManufacturingLanding;
