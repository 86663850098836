import React from "react";

import ManufacturingCard from "../../components/manufacturing-card/ManufacturingCard";
import ContactComponent from "../../components/contact/ContactComponent";
import LinkComponent from "../../components/link-section/LinkComponent";
import VideoComponent from "../../components/video-component/VideoComponent";

import HeroImg from "../../assets/imgs/hero-imgs/amp_precision-2.jpg";
import CardOne from "../../assets/imgs/card-images/cnc-milling.png";
import CardTwo from "../../assets/imgs/card-images/cnc-turning.png";
import CardThree from "../../assets/imgs/card-images/cnc-drilling.png";
import CardFour from "../../assets/imgs/card-images/cnc-4.png";
import CncThumbnail from "../../assets/imgs/thumbnails/cnc-thumbnail.png";

import "../../assets/styles/template.css";
import "../../assets/styles/template-mobile.css";

const CNCPage = () => {
  const cardData = [
    {
      img: CardOne,
      title: "Milling",
      text: "Our milling capabilities allow us to create parts with complex geometries, including features like pockets, holes, and slots. We can work with a variety of materials, including metals, plastics, and composites.",
      orientation: false,
    },
    {
      img: CardTwo,
      title: "Turning",
      text: "Our turning capabilities allow us to create cylindrical parts with high accuracy and precision. We work with a variety of materials and provide cost friendly solutions, regardless of volume.",
      orientation: true,
    },
    {
      img: CardThree,
      title: "Drilling",
      text: "Our drilling capabilities allow us to create precise holes in a variety of materials. We can drill both simple and complex holes, including those with custom geometries.",
      orientation: false,
    },
    {
      img: CardFour,
      title: "Grinding",
      text: "Our grinding capabilities allow us to create high-precision parts with a smooth surface finish. We can grind both flat and round surfaces, and can work with a variety of materials.",
      orientation: true,
    },
  ];

  const infoData = {
    text: "Our CNC machining capabilities have been utilized by clients in a variety of industries, including aerospace, automotive, medical, and more. We have produced everything from simple components to complex assemblies, and our team is dedicated to delivering quality results on time and within budget.",
    video: "https://www.youtube.com/embed/s8N2sFCaokM?si=U7tJjE6eikiC28W4",
    thumbnail: CncThumbnail,
  };

  return (
    <div>
      <section
        className="hero-section"
        style={{ backgroundImage: `url(${HeroImg})` }}
      >
        <div className="hero-shader"></div>
        <div className="hero-header">
          <h1>CNC</h1>
          <h4>Precision crafted, expertly machined</h4>
        </div>
      </section>
      <section className="intro-section">
        <p>
          We are proud to offer a wide range of high-quality CNC machining
          services to meet the needs of our clients. Our state-of-the-art
          equipment and experienced machinists allow us to provide precision
          machining solutions for a variety of industries.
        </p>
        <div className="intro-section">
          <h3>What is CNC Machining?</h3>
          <p>
            CNC machining is a process that uses computer-controlled machines to
            create complex parts and components with high precision and
            accuracy. CNC machines use computer-aided design (CAD) software to
            create 3D models of the desired part, and then use those models to
            guide the machining process. This results in parts that are highly
            accurate, repeatable, and can be produced quickly and efficiently.
          </p>
        </div>
      </section>
      <section>
        <section className="card-container">
          <h2>CNC CAPABILITIES</h2>
          {cardData.map((card, key) => {
            return (
              <ManufacturingCard
                key={key}
                img={card.img}
                title={card.title}
                text={card.text}
                orientation={card.orientation}
              />
            );
          })}
        </section>
      </section>
      <section className="info-section">
        <VideoComponent data={infoData} />
      </section>
      <section>
        <LinkComponent />
      </section>
      <section>
        <ContactComponent />
      </section>
    </div>
  );
};

export default CNCPage;
