import React from "react";

import ManufacturingCard from "../../components/manufacturing-card/ManufacturingCard";
import ContactComponent from "../../components/contact/ContactComponent";
import LinkComponent from "../../components/link-section/LinkComponent";
import VideoComponent from "../../components/video-component/VideoComponent";

import HeroImg from "../../assets/imgs/hero-imgs/IMG_0818.JPG";
import CardOne from "../../assets/imgs/card-images/qa-1.png";
// import CardTwo from "../../assets/imgs/card-images/qa-2.png";
import QaThumbnail from "../../assets/imgs/thumbnails/qa-thumbnail.png";
import CardTwo from "../../assets/imgs/card-images/cnc-1.png";

import "../../assets/styles/template.css";
import "../../assets/styles/template-mobile.css";

const QAPage = () => {
  const cardData = [
    {
      img: CardOne,
      title: "ISO 9001:2015 certified",
      text: "We are proud to be ISO 9001:2015 certified, a globally recognized standard for quality management systems. This certification means that our manufacturing processes have been independently audited and verified to meet rigorous quality standards.",
      orientation: false,
    },
    {
      img: CardTwo,
      title: "Quality Control",
      text: "We use advanced quality control measures and testing procedures to ensure that our products are of the highest quality, and our team is dedicated to delivering products that meet your exact specifications with precision and accuracy. ",
      orientation: true,
    },
  ];

  const infoData = {
    text: null,
    video: null,
    thumbnail: QaThumbnail,
  };

  return (
    <div>
      <section
        className="hero-section"
        style={{ backgroundImage: `url(${HeroImg})` }}
      >
        <div className="hero-shader"></div>
        <div className="hero-header">
          <h1>QUALITY ASSURANCE</h1>
          <h4>The highest standards of excellence</h4>
        </div>
      </section>
      <section className="intro-section">
        <p>
          Quality assurance is a top priority for us, and we take every step
          necessary to ensure that our products meet the highest standards of
          excellence. We are committed to continuously improving our processes
          and products to ensure that we meet the changing needs of our
          customers. Trust us to provide you with high-quality products that are
          backed by a commitment to quality assurance.
        </p>
      </section>
      <section className="card-container">
        <h2>HOW WE'RE DIFFERENT</h2>
        {cardData.map((card, key) => {
          return (
            <ManufacturingCard
              key={key}
              img={card.img}
              title={card.title}
              text={card.text}
              orientation={card.orientation}
            />
          );
        })}
      </section>
      {infoData.video && (
        <section className="info-section">
          <VideoComponent data={infoData} />
        </section>
      )}
      <section>
        <LinkComponent />
      </section>
      <section>
        <ContactComponent />
      </section>
    </div>
  );
};

export default QAPage;
