import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";

import ImgOne from "../../assets/imgs/carousel-imgs/slider/1.png";
import ImgTwo from "../../assets/imgs/carousel-imgs/slider/2.png";
import ImgThree from "../../assets/imgs/carousel-imgs/slider/3.png";
import ImgFour from "../../assets/imgs/carousel-imgs/slider/4.png";
import ImgFive from "../../assets/imgs/carousel-imgs/slider/5.png";
import ImgSix from "../../assets/imgs/carousel-imgs/slider/6.png";

import "./carouselOne.css";
import "swiper/css";

const CarouselOne = () => {
  const collectiondata = [ImgOne, ImgTwo, ImgThree, ImgFour, ImgFive, ImgSix];
  return (
    <Swiper
      className="carousel-one-swiper"
      slidesPerView="auto"
      spaceBetween={0}
      centeredSlides={true}
      loop={true}
      autoplay={{
        delay: 0,
        disableOnInteraction: false,
      }}
      speed={6000}
      modules={[Autoplay]}
    >
      {collectiondata.map((collection, key) => {
        if (key !== 3) {
          return (
            <SwiperSlide
              id="swiper-one-slide"
              className="carousel-one-slide"
              key={key}
            >
              <img
                className="carousel-one-image"
                src={collection}
                alt="collection"
              />
            </SwiperSlide>
          );
        }
      })}
    </Swiper>
  );
};

export default CarouselOne;
