import { useEffect, useRef, useState } from "react";
import PlayButton from "../../assets/imgs/icons/video-play-btn.svg";

import "../../assets/styles/template.css";
import "../../assets/styles/template-mobile.css";

const VideoComponent = ({ data }) => {
  const [play, setPlay] = useState("?modestbranding=1");
  const [hideImage, setHideImage] = useState(false);
  const videoRef = useRef();
  useEffect(() => {
    // console.log(videoRef.current);
  }, [videoRef]);
  return (
    <div className="video-component">
      <h2>OUR CAPABILITIES IN ACTION</h2>
      {data && data.text && <p>{data.text}</p>}
      <div
        className="video-component-video"
        style={{ backgroundImage: `url(${data.thumbnail})` }}
      >
        {/* {!hideImage && (
          <div
            style={{
              backgroundImage: `url(${data.thumbnail})`,
            }}
            className="video-component-thumbnail-overlay"
            onClick={() => setPlay("?autoplay=1") & setHideImage(true)}
          >
            <img
              className="video-component-play-btn"
              src={PlayButton}
              alt="play button"
              onClick={() => setPlay("?autoplay=1") & setHideImage(true)}
            />
          </div>
        )} */}
        {data && data.video && (
          <iframe
            ref={videoRef}
            width="100%"
            height="100%"
            src={data.video + play}
            title="YouTube video player"
            frameBorder="0"
            allowFullScreen
            modestbranding="1"
          ></iframe>
        )}
      </div>
    </div>
  );
};

export default VideoComponent;
