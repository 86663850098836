const patentedFoldedFrameTechnology = {
  title: "Patented Folded-Frame Technology ",
  doc: "AmpThink enclosures are cost-effective and offer industry-leading strength due to their unique design: they are molded around an aluminum rail or skeleton created with our patented folded-frame technology. This design ensures reliable mating of the two enclosure halves without interfering with RF propagation. In contrast, competitive systems constructed solely of plastic can warp when exposed to heat cycles, resulting in unreliable sealing. Additionally, their plastic mounting feet can break easily, rendering the enclosure useless. With AmpThink enclosures, the folded frame houses our gasket system and serves as the attachment point for our mounting systems, ensuring that our systems hold up in the most demanding environments, such as when mounted under seats in a stadium.",
};

const gasketSystem = {
  title: "Gasket System",
  doc: "Competitive systems made entirely of plastic are prone to warping during heat cycles, resulting in unreliable sealing. Additionally, plastic mounting feet are easily breakable, rendering the entire enclosure useless. AmpThink enclosures, on the other hand, feature a folded frame that houses our gasket system and serves as the attachment point for our mounting systems, ensuring that our systems hold up in the most demanding environments, such as when mounted under stadium seats.",
};

const ToolLessInternalBracketry = {
  title: "Tool-less Internal Bracketry ",
  doc: "Say goodbye to broken plastic fastener bosses designed for self-taping screws. AmpThink enclosures feature internal mounting brackets that snap into the folded frame via an accessory groove, allowing electronics to be mounted outside of the box and then snapped in. Standard mounting slots are provided for most manufacturer's access points, eliminating the need for OEM supplied mounts and reducing the overall depth of mounting, enabling thinner enclosures. For applications requiring both an access point and an antenna, our stackable brackets simplify the build process. ",
};

const interiorData = [
  patentedFoldedFrameTechnology,
  ToolLessInternalBracketry,
  gasketSystem,
];

export default { interiorData };
