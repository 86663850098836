import React from "react";
import { AiOutlineClose } from "react-icons/ai";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";

import VideoData from "../../util/data/VideoData";

import "./collection.css";

const VideoModal = ({ setIsVideoModal, videoPlaying, setVideoPlaying }) => {
  const closeVideo = () => {
    setIsVideoModal(false);
  };

  const nextVideo = () => {
    if (videoPlaying < VideoData.length - 1) {
      setVideoPlaying((videoPlaying) => videoPlaying + 1);
    } else {
      setVideoPlaying(videoPlaying);
    }
  };

  const prevVideo = () => {
    if (videoPlaying > 0) {
      setVideoPlaying((videoPlaying) => videoPlaying - 1);
    } else {
      setVideoPlaying(videoPlaying);
    }
  };

  return (
    <div className="video-modal-container">
      <div className="video-modal-header">
        <div></div>
        <AiOutlineClose
          className="video-close-btn"
          onClick={() => closeVideo()}
        />
      </div>
      <div className="video-modal-iframe-container">
        <button
          className="video-modal-btn left-btn"
          onClick={() => prevVideo()}
        >
          <IoIosArrowBack className={videoPlaying <= 0 && "disabled"} />
        </button>
        <iframe
          className="video-modal-iframe"
          width="65%"
          height="500px"
          src={VideoData[videoPlaying] && VideoData[videoPlaying].video}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
        <button
          className="video-modal-btn right-btn"
          onClick={() => nextVideo()}
        >
          <IoIosArrowForward
            className={videoPlaying === VideoData.length - 1 && "disabled"}
          />
        </button>
      </div>
    </div>
  );
};

export default VideoModal;
