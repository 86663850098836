import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";

import imgOne from "../../assets/imgs/carousel-imgs/enclosures-hero-1.png";
import imgTwo from "../../assets/imgs/carousel-imgs/enclosures-hero-2.png";
import imgThree from "../../assets/imgs/carousel-imgs/enclosures-hero-3.png";

import "./carouselTwo.css";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

const CarouselTwo = () => {
  const tempArray = [{ img: imgOne }, { img: imgTwo }, { img: imgThree }];

  return (
    <Swiper
      id="carousel-two"
      slidesPerView={1}
      spaceBetween={10}
      centeredSlides
      loop={true}
      pagination={{
        clickable: true,
      }}
      autoplay={{
        delay: 7000,
        disableOnInteraction: false,
      }}
      speed={1500}
      navigation={true}
      modules={[Autoplay, Navigation]}
      className="mySwiper carousel-two-swiper"
    >
      {tempArray.map((stadium, key) => {
        return (
          <SwiperSlide key={key} className="carousel-two-slide">
            <div
              style={{
                backgroundImage: `url(${stadium.img})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                position: "relative",
              }}
            >
              <div className="hero-gradient"></div>
            </div>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default CarouselTwo;
