import React from "react";

import ManufacturingCard from "../../components/manufacturing-card/ManufacturingCard";
import ContactComponent from "../../components/contact/ContactComponent";
import LinkComponent from "../../components/link-section/LinkComponent";
import VideoComponent from "../../components/video-component/VideoComponent";

import HeroImg from "../../assets/imgs/hero-imgs/IMG_2043.jpg";
import CardOne from "../../assets/imgs/card-images/cable-assembly-1.png";
import CardTwo from "../../assets/imgs/card-images/cable-assembly-2.png";
import CableAssemblyThumbnail from "../../assets/imgs/thumbnails/cable-assembly-thumbnail.png";

import "../../assets/styles/template.css";
import "../../assets/styles/template-mobile.css";

const CableAssemblyPage = () => {
  const cardData = [
    {
      img: CardOne,
      title: "Expertise",
      text: "Our skilled team of engineers and technicians have extensive experience working with fiber optic cables, and can provide solutions for a wide range of applications, including telecommunications, data centers, and industrial automation.",
      orientation: false,
    },
    {
      img: CardTwo,
      title: "Materials",
      text: "We offer a variety of connector types, including LC, SC, ST, and MTP/MPO, and can provide custom labeling and packaging to meet your branding and logistical needs.",
      orientation: true,
    },
  ];

  const infoData = {
    text: null,
    video: "https://www.youtube.com/embed/srQloKWR4l4",
    thumbnail: CableAssemblyThumbnail,
  };

  return (
    <div>
      <section
        className="hero-section"
        style={{ backgroundImage: `url(${HeroImg})` }}
      >
        <div className="hero-shader"></div>
        <div className="hero-header">
          <h1>CABLE ASSEMBLY</h1>
          <h4>Specialized solutions for exact requirements</h4>
        </div>
      </section>
      <section className="intro-section">
        <p>
          Our cable assembly services offer a range of specialized solutions,
          including fiber optic cable assemblies, pig tails, and cassettes. We
          use high-quality components and advanced techniques to create
          customized cable assemblies that meet your exact requirements. Trust
          us to deliver high-quality cable assemblies that are designed to meet
          your specific needs, weather you need a single prototype or a large
          production run.
        </p>
      </section>
      <section className="card-container">
        <h2>HOW WE'RE DIFFERENT</h2>
        {cardData.map((card, key) => {
          return (
            <ManufacturingCard
              key={key}
              img={card.img}
              title={card.title}
              text={card.text}
              orientation={card.orientation}
            />
          );
        })}
      </section>
      <section className="info-section">
        <VideoComponent data={infoData} />
      </section>
      <section>
        <LinkComponent />
      </section>
      <section>
        <ContactComponent />
      </section>
    </div>
  );
};

export default CableAssemblyPage;
