import React from "react";
import FacebookIcon from "../../assets/imgs/icons/social/fb.svg";
import TwitterIcon from "../../assets/imgs/icons/social/x-logo.svg";
import LinkedinIcon from "../../assets/imgs/icons/social/in.svg";

const SocialLinks = ({ mobile }) => {
  return (
    <div
      style={
        mobile && {
          display: "flex",
          justifyContent: "center",
          paddingTop: "25px",
          marginTop: "10px",
          borderTop: "1px solid #fff",
        }
      }
      className="header-social-icon-container"
    >
      <a href="https://www.facebook.com/AmpThink/" target="blank">
        <img src={FacebookIcon} alt="facebook icon" />
      </a>
      <a href="https://twitter.com/ampthink" target="blank">
        <img src={TwitterIcon} alt="twitter icon" />
      </a>
      <a href="https://www.linkedin.com/company/ampthink" target="blank">
        <img src={LinkedinIcon} alt="linkedin icon" />
      </a>
    </div>
  );
};

export default SocialLinks;
