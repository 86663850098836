import { useState, useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";

import ContactComponent from "../../components/contact/ContactComponent";
import KitIncludes from "../../components/kit-includes/KitIncludes";
import EnclosureData from "../../util/data/all-products/EnclosureData";
import ApCovers from "../../util/data/all-products/APCovers";
import MountingBrackets from "../../util/data/all-products/MountingBrackets";

import pdfSvg from "../../assets/imgs/icons/PDF_icon.svg";

import "../../assets/styles/singleProductPage.css";

const SingleProductPage = () => {
  const [enclosure, setEnclosure] = useState({});
  const [enclosureData, setEnclosureData] = useState({});
  const [mainImg, setMainImg] = useState("enc 1");
  const [selectedImg, setSelectedImg] = useState(0);
  const location = useLocation();
  let { enclosureId, selectedProduct, index } = useParams();

  useEffect(() => {
    if (location.state) {
      setEnclosure(location.state);
      setEnclosureData(location.state);
    } else {
      if (selectedProduct === "Enclosures") {
        setEnclosure(EnclosureData[index]);
        setEnclosureData(EnclosureData[index]);
      } else if (selectedProduct === "Vanity Covers") {
        setEnclosure(ApCovers[index]);
        setEnclosureData(ApCovers[index]);
      } else if (selectedProduct === "Mounting Brackets") {
        setEnclosure(MountingBrackets[index]);
        setEnclosureData(MountingBrackets[index]);
      }
    }
  }, [location]);

  useEffect(() => {
    setMainImg(enclosure.img);
  }, [enclosure]);

  const handleImgChange = (img, key) => {
    setMainImg(img);
    setSelectedImg(key);
  };

  return (
    <>
      <div className="single-product-page">
        <div className="breadcrumb-container">
          <ul>
            <Link to="/products">
              <li>Products</li>
            </Link>
            <p>{`/`}</p>
            <Link to="/products">
              <li style={{ color: "var(--brownish-orange)" }}>
                {sessionStorage.getItem("selectedProduct")
                  ? sessionStorage.getItem("selectedProduct")
                  : "Enclosures"}
              </li>
            </Link>
            <p
              style={{ color: "var(--brownish-grey)", opacity: ".8" }}
            >{`/`}</p>
            <li style={{ opacity: ".8" }}>{enclosure.title}</li>
          </ul>
        </div>
        <div
          className="single-product-main-content"
          style={{ position: "relative" }}
        >
          <div className="single-product-img-container">
            <div>
              <div className="single-product-main-img">
                <img className="main-img" src={mainImg} alt={mainImg} />
              </div>
              <div className="single-product-other-imgs">
                {enclosure &&
                  enclosure.imgArr &&
                  enclosure.imgArr.map((img, key) => {
                    return (
                      <div
                        key={key}
                        style={
                          key === selectedImg
                            ? { border: "1px solid var(--brownish-orange)" }
                            : {}
                        }
                        onClick={() => handleImgChange(img, key)}
                      >
                        <img src={img} alt={`enc ` + img} />
                      </div>
                    );
                  })}
              </div>
              <div className="single-product-resources">
                <h3>Resources</h3>
                <div>
                  <div>
                    <img
                      src={pdfSvg}
                      alt="pdf download"
                      style={{
                        filter:
                          "invert(56%) sepia(62%) saturate(5971%) hue-rotate(348deg) brightness(93%) contrast(84%)",
                        height: "16px",
                        margin: "auto 0",
                      }}
                    />
                    <a
                      style={{
                        color: "var(--brownish-orange)",
                        marginLeft: "5px",
                      }}
                      href={(enclosure && enclosure.pdf) || ""}
                      target={enclosure && enclosure.pdf && "_blank"}
                      rel="noreferrer"
                    >
                      Specification Sheet
                    </a>
                  </div>
                  <div>
                    {enclosure && enclosure.installGuide && (
                      <img
                        src={pdfSvg}
                        alt="pdf download"
                        style={{
                          filter:
                            "invert(56%) sepia(62%) saturate(5971%) hue-rotate(348deg) brightness(93%) contrast(84%)",
                          height: "16px",
                          margin: "auto 0",
                        }}
                      />
                    )}
                    {enclosure && enclosure.installGuide && (
                      <a
                        style={{
                          color: "var(--brownish-orange)",
                          marginLeft: "5px",
                        }}
                        href={(enclosure && enclosure.installGuide) || ""}
                        target={enclosure && enclosure.installGuide && "_blank"}
                        rel="noreferrer"
                      >
                        Installation Guide
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="single-product-data-container">
            <div
              style={{
                width: "100%",
                maxWidth: "1250px",
                marginTop: "0",
              }}
            >
              <h1 style={{ fontFamily: "openSans-Bold" }}>
                {enclosure && enclosure.title && enclosure.title}
              </h1>
              <h2>{enclosure && enclosure.partNum && enclosure.partNum}</h2>
            </div>
            <h3>Overview</h3>
            <p>{enclosure && enclosure.description && enclosure.description}</p>
            <div>
              {enclosure && enclosure.simplicity && enclosure.simplicity && (
                <div>
                  <h3>Simplicity maximized</h3>
                  <p>{enclosure.simplicity}</p>
                </div>
              )}
            </div>

            <div>
              {enclosure &&
                enclosure.technicalSpecs &&
                enclosure.technicalSpecs.length > 0 && (
                  <h3>Technical Specifications</h3>
                )}
              {enclosure &&
                enclosure.technicalSpecs &&
                enclosure.technicalSpecs.map((specs, key) => {
                  return (
                    <div key={key}>
                      <p style={{ margin: "5px 0" }}>{specs}</p>
                      {key !== enclosure.technicalSpecs.length - 1 && <hr />}
                    </div>
                  );
                })}
            </div>

            <div>
              {enclosure &&
                enclosure.compatibleHardware &&
                enclosure.compatibleHardware.length > 0 && (
                  <h3>Compatible Hardware</h3>
                )}
              {enclosure &&
                enclosure.compatibleHardware &&
                enclosure.compatibleHardware.map((specs, key) => {
                  return (
                    <div key={key}>
                      <p style={{ margin: "5px 0" }}>{specs[0]}</p>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        {specs.length > 0 &&
                          specs.map((spec, key) => {
                            if (key !== 0) {
                              return (
                                <span
                                  key={spec}
                                  style={{ paddingLeft: "10px" }}
                                >
                                  {spec}
                                </span>
                              );
                            }
                          })}
                      </div>
                    </div>
                  );
                })}
            </div>

            <div>
              {enclosure &&
                enclosure.features &&
                enclosure.features.length > 0 && <h3>Features</h3>}
              <ul>
                {enclosure &&
                  enclosure.features &&
                  enclosure.features.map((feature, key) => {
                    return (
                      <li style={{ marginLeft: "20px" }} key={key}>
                        {feature}
                      </li>
                    );
                  })}
              </ul>
            </div>

            <div>
              {enclosure &&
                enclosure.kitIncludes &&
                enclosure.kitIncludes.length > 0 && <h3>Kit Includes</h3>}
              <ul>
                {enclosure &&
                  enclosure.kitIncludes &&
                  enclosure.kitIncludes.map((data, key) => {
                    return (
                      <li style={{ marginLeft: "20px" }} key={key}>
                        {data}
                      </li>
                    );
                  })}
              </ul>
            </div>
            <div>
              {enclosure &&
                enclosure.kitOptions &&
                enclosure.kitOptions.length > 0 && <h3>Options</h3>}
              <ul>
                {enclosure &&
                  enclosure.kitOptions &&
                  enclosure.kitOptions.map((include, key) => {
                    return (
                      <li style={{ marginLeft: "20px" }} key={key}>
                        {include}
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
        </div>
        {/* <KitIncludes data={enclosure.kitIncludesData} /> */}
      </div>
      <ContactComponent />
    </>
  );
};

export default SingleProductPage;
