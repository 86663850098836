import React from "react";
import { Link } from "react-router-dom";

import "../../assets/styles/template.css";
import "../../assets/styles/template-mobile.css";

const ContactComponent = () => {
  return (
    <div className="contact-component">
      <h3>Ready to get started?</h3>
      <p style={{ marginBottom: "20px" }}>
        Our team is here to help and answer any questions you may have.
      </p>
      <Link to="/contact" className="contact-btn">
        CONTACT OUR TEAM TODAY
      </Link>
    </div>
  );
};

export default ContactComponent;
