import React from "react";
import { IoIosArrowUp } from "react-icons/io";

import AmpthinkLogo from "../../assets/imgs/icons/amp-mfg-logo.png";
import FacebookIcon from "../../assets/imgs/icons/social/fb.svg";
import TwitterIcon from "../../assets/imgs/icons/social/x-logo.svg";
import LinkedinIcon from "../../assets/imgs/icons/social/in.svg";
import YoutubeIcon from "../../assets/imgs/icons/social/yt.png";

import "./footer.css";

const Footer = () => {
  return (
    <div className="footer-container">
      <div className="footer-content-container">
        <div>
          <img
            className="ampthink-footer-logo"
            src={AmpthinkLogo}
            alt="ampthink logo"
          />
        </div>
        <a href="#top">
          <IoIosArrowUp className="up-arrow" />
        </a>
        <div className="social-icon-container">
          <a href="https://www.facebook.com/AmpThink/" target="blank">
            <img src={FacebookIcon} alt="facebook icon" />
          </a>
          <a href="https://twitter.com/ampthink" target="blank">
            <img src={TwitterIcon} alt="twitter icon" />
          </a>
          <a href="https://www.linkedin.com/company/ampthink" target="blank">
            <img src={LinkedinIcon} alt="linkedin icon" />
          </a>
          <a
            href="https://www.youtube.com/channel/UCmg59bL0oA7m92FkEgz9dXA"
            target="blank"
          >
            <img src={YoutubeIcon} alt="youtube icon" />
          </a>
        </div>
      </div>
      <div className="footer-copyright-container">
        <p>All material copyright &copy; 2022 AmpThink Manufacturing</p>
      </div>
    </div>
  );
};

export default Footer;
