const VideoOne = {
  title: "ampbox",
  video: "https://www.youtube.com/embed/ew0oq4UgPWg",
};

const VideoTwo = {
  title: "slim profile",
  video: "https://www.youtube.com/embed/yWLqNhydIG4",
};

const VideoThree = {
  title: "handrails",
  video: "https://www.youtube.com/embed/8eOgDf5mAGw",
};

const VideoFour = {
  title: "seat enclosures",
  video: "https://www.youtube.com/embed/F7j2I9Mqtks",
};

const VideoFive = {
  title: "single trade enclosure",
  video: "https://www.youtube.com/embed/Ps0_3S2OoLA?si=6kTxVmartqu6tu5o",
};

const VideoData = [VideoOne, VideoTwo, VideoThree, VideoFour, VideoFive];

export default VideoData;
