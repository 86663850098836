import React from "react";
import { IoMdClose } from "react-icons/io";

import "./new-interactable.css";

const InteractableModal = ({ data, setShowDescription }) => {
  const handleClose = () => {
    setShowDescription(false);
  };

  return (
    <div className="interactable-data-modal">
      <IoMdClose
        className="interactable-modal-close-btn"
        onClick={() => handleClose()}
      />
      <h3>{data.title}</h3>
      <div>{data.doc}</div>
    </div>
  );
};

export default InteractableModal;
