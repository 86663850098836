import { useState } from "react";
import ContactComponent from "../../components/contact/ContactComponent.js";
import { Link } from "react-router-dom";
import { IoMdArrowDropright } from "react-icons/io";

import EquipmentImg from "../../assets/imgs/hero-imgs/IMG_0850.JPG";
import QualityImg from "../../assets/imgs/hero-imgs/IMG_0818.JPG";
import SheetMetalImg from "../../assets/imgs/hero-imgs/IMG_0807.JPG";
import CNCImg from "../../assets/imgs/hero-imgs/amp_precision-2.jpg";
import CableAssemblyImg from "../../assets/imgs/hero-imgs/IMG_2043.jpg";
import ThermoformingImg from "../../assets/imgs/hero-imgs/IMG_0881.JPG";

import "../../assets/styles/fabrication.css";
import "../../assets/styles/template.css";
import "../../assets/styles/template-mobile.css";

const FabricationPage = () => {
  const [linkImg, setLinkImg] = useState(EquipmentImg);

  const links = [
    {
      name: "Equipment",
      link: "/equipment",
      img: EquipmentImg,
    },
    {
      name: "Quality Assurance",
      link: "/quality-assurance",
      img: QualityImg,
    },
    {
      name: "Sheet Metal Bending",
      link: "/metal-bending",
      img: SheetMetalImg,
    },
    {
      name: "CNC",
      link: "/cnc",
      img: CNCImg,
    },
    {
      name: "Cable Assembly",
      link: "/cable-assembly",
      img: CableAssemblyImg,
    },
    {
      name: "Thermoforming",
      link: "/thermoforming",
      img: ThermoformingImg,
    },
  ];

  return (
    <div className="fabrication-page">
      <div className="fabrication-hero" style={{ backgroundSize: "cover" }}>
        <div className="fabrication-hero-shader"></div>
        <div className="hero-header">
          <h1>CUSTOM MANUFACTURING</h1>
          <h4 style={{ color: "#fff" }}>
            Precision made custom brackets, mounts, enclosures and more
          </h4>
        </div>
      </div>
      <div className="fabrication-intro">
        <h2>PRECISION SERVICES TO COMPANIES AROUND THE GLOBE</h2>
        <p>
          At AmpThink Manufacturing, we deliver top-notch design for
          manufacturing services to companies worldwide. We understand that time
          is money, and that is why we work tirelessly to provide you with the
          best-manufactured product in the shortest amount of time possible. By
          combining creativity and science, we can move from prototype to
          production quickly and effectively.
        </p>
      </div>
      <div className="fabrication-about">
        <div className="fabrication-about-left-img"></div>
        <div className="fabrication-about-right-info">
          <h3>OUR SERVICES</h3>
          <p>
            We provide Engineering Design, Precision Machining,
            Manufacturing/Assembly and QA and Inspection Services.
          </p>
          <ul>
            <li>20+ CNC machining and lathe centers</li>
            <li>ISO 9001:2015 Certified</li>
            <li>
              Proven track record of delivering complex projects in a timely
              manner
            </li>
          </ul>
        </div>
      </div>
      <div className="fabrication-collab-intro">
        <h3>A Collaborative Partner</h3>
        <p>
          Our collaborative "E to E" (engineer to engineer) process helps to
          reduce project time and cost. We apply only the best practices for
          value engineering and manufacturing processes. Flexible manufacturing
          processes support quick-turn, rapid prototyping and volume production.
          Plus, our long-standing culture of quality and attention to detail is
          paramount within each discipline.
        </p>
      </div>
      <div className="link-component">
        <h2>OUR CAPABILITIES</h2>
        <div className="custom-manufacturing-link-container">
          <div
            className="custom-manufacturing-link-img"
            style={{ backgroundImage: `url(${linkImg})` }}
          ></div>
          <div className="custom-manufacturing-links">
            {links.map((link, key) => {
              return (
                <div
                  key={key}
                  className="link-container-container"
                  style={{ width: "80%" }}
                  onMouseEnter={() => link.img && setLinkImg(link.img)}
                >
                  <div>
                    <Link to={link.link}>
                      <h3 style={{ margin: "0" }}>{link.name}</h3>
                      <IoMdArrowDropright className="link-component-arrow" />
                    </Link>
                  </div>
                  <hr />
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <ContactComponent />
    </div>
  );
};

export default FabricationPage;
