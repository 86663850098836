import { useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

import AmpLogo from "../../assets/imgs/icons/amp-mfg-logo.png";
import AmpthinkLogo from "../../assets/imgs/icons/ampthink-logo.svg";
import AmpboardsLogo from "../../assets/imgs/icons/ampboards-logo.svg";
import RapidToolLogo from "../../assets/imgs/icons/rapidtool-logo-grey.svg";
import SocialLinks from "./SocialLinks";

import { HiMenu } from "react-icons/hi";
import { AiOutlineClose } from "react-icons/ai";

import MobileMenu from "./MobileMenu";

import "../../assets/styles/header.css";

const Header = () => {
  const [mobileMenuShow, setMobileMenuShow] = useState(false);
  const [showDropDown, setShowDropDown] = useState(false);

  const location = useLocation();

  const toggleMenu = () => {
    setMobileMenuShow((mobileMenuShow) => !mobileMenuShow);
  };

  return (
    <div className="header-container">
      <div className="header-content">
        <div className="header-max-width">
          <div className="header-logo-container">
            <Link to="https://www.ampthink.com/" target={"_blank"}>
              <img src={AmpthinkLogo} alt="ampthink-logo" />
            </Link>
            <Link to="https://www.ampboards.com/" target={"_blank"}>
              <img
                style={{ marginBottom: "5px" }}
                src={AmpboardsLogo}
                alt="ampboards-logo"
              />
            </Link>
            <Link to="https://rapidtool.com/" target={"_blank"}>
              <img src={RapidToolLogo} alt="rapid tool logo" />
            </Link>
          </div>
          <SocialLinks />
        </div>
      </div>
      <div className="nav">
        <div className="header-max-width">
          <Link to="/">
            <img className="amp-logo-header" src={AmpLogo} alt="amp-logo" />
          </Link>
          <div className="menu-items">
            <div
              className="dropdown-selector"
              to="/custom-manufacturing"
              onMouseEnter={() => setShowDropDown(true)}
              onMouseLeave={() => setShowDropDown(false)}
              onClick={() => setShowDropDown(false)}
            >
              <Link
                to="/custom-manufacturing"
                style={
                  location.pathname === "/custom-manufacturing" ||
                  location.pathname === "/equipment" ||
                  location.pathname === "/metal-bending" ||
                  location.pathname === "/cable-assembly" ||
                  location.pathname === "/quality-assurance" ||
                  location.pathname === "/cnc"
                    ? {
                        color: "var(--brownish-orange)",
                      }
                    : {}
                }
              >
                CUSTOM MANUFACTURING
              </Link>
              <div
                className="menu-dropdown"
                style={showDropDown ? { display: "flex" } : { display: "none" }}
              >
                <Link to="/equipment" onClick={() => setShowDropDown(false)}>
                  Equipment
                </Link>
                <Link
                  to="/metal-bending"
                  onClick={() => setShowDropDown(false)}
                >
                  Sheet Metal Bending
                </Link>
                <Link
                  to="/cable-assembly"
                  onClick={() => setShowDropDown(false)}
                >
                  Cable Assembly
                </Link>
                <Link
                  to="/quality-assurance"
                  onClick={() => setShowDropDown(false)}
                >
                  Quality Assurance
                </Link>
                <Link to="/cnc" onClick={() => setShowDropDown(false)}>
                  CNC
                </Link>
                <Link
                  to="/thermoforming"
                  onClick={() => setShowDropDown(false)}
                >
                  Thermoforming
                </Link>
              </div>
            </div>
            <Link to="/enclosures">
              <div
                style={
                  location.pathname === "/enclosures"
                    ? {
                        color: "var(--brownish-orange)",
                      }
                    : {}
                }
              >
                ENCLOSURES
              </div>
            </Link>
            {/* <Link to="/build">
            <div>CONFIGURATOR</div>
          </Link> */}
            <Link to="/products">
              <div
                style={
                  location.pathname === "/products" ||
                  location.pathname.includes("/single-enclosure")
                    ? {
                        color: "var(--brownish-orange)",
                      }
                    : {}
                }
              >
                PRODUCTS
              </div>
            </Link>
            <Link to="/contact" style={{ marginRight: "0" }}>
              <div
                style={
                  location.pathname === "/contact"
                    ? {
                        color: "var(--brownish-orange)",
                      }
                    : {}
                }
              >
                CONTACT
              </div>
            </Link>
          </div>

          {!mobileMenuShow ? (
            <HiMenu className="mobile-menu-btn" onClick={() => toggleMenu()} />
          ) : (
            <AiOutlineClose
              className="mobile-menu-btn"
              onClick={() => toggleMenu()}
            />
          )}
        </div>
        <MobileMenu func={toggleMenu} mobileMenuShow={mobileMenuShow} />
      </div>
    </div>
  );
};

export default Header;
