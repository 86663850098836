import Cover1 from "../../../assets/imgs/enclosure-photos/Covers/Aruba AP cover.png";
import Cover3 from "../../../assets/imgs/enclosure-photos/Covers/Cisco antenna cover 1.png";
import Cover4 from "../../../assets/imgs/enclosure-photos/Covers/Cisco antenna cover 2.png";
import Cover5 from "../../../assets/imgs/enclosure-photos/Covers/Cisco antenna cover 3.png";
import Cover7 from "../../../assets/imgs/enclosure-photos/Covers/Cisco AP cover.png";
import Cover9 from "../../../assets/imgs/enclosure-photos/Covers/Aruba AP shroud.png";
import Cover10 from "../../../assets/imgs/enclosure-photos/Covers/Cisco AP shroud.png";
import Cover11 from "../../../assets/imgs/enclosure-photos/Covers/Cisco antenna cover 4.png";

import Pdf1 from "../../../assets/vanity-pdf/VC-AR500.pdf";
import Pdf2 from "../../../assets/vanity-pdf/VC-C9120AXI.pdf";
import Pdf3 from "../../../assets/vanity-pdf/VC-C9130AXE-STA.pdf";

import ImgPlaceholder from "../../../assets/imgs/icons/image-placeholder.png";

const APCover1 = {
  id: 1,
  partNum: "VC-AR500",
  dimensions: "9.712” x 9.627” x 2.284” ",
  title: "Vanity Cover for Aruba 500 Series AP",
  img: Cover1,
  pdf: Pdf1,
  description:
    "The VC-C9120AXI fits seamlessly over the top of any Aruba 500 Series Access Point. This durable ABS plastic provides added protection against bumps and scratches and comes in a wide variety of colors to fit your specific needs.",
  simplicity:
    "The VC-AR500 is designed with recessed “catch” features on each corner of the cover for instant installation, no tools required. The cover features a peep hole that allows end users to see the status light without removing the cover. A tethering accessory is provided to comply with seismic requirements. ",
  technicalSpecs: ["Dimensions: 9.712” x 9.627” x 2.284” ", "Weight: 1lbs"],
  kitIncludes: ["VC-AR500 AP Vanity Cover", "Tethering accessory"],
  kitIncludesData: [
    { img: Cover1, title: "VC-AR500 AP Vanity Cover" },
    { img: ImgPlaceholder, title: "Tethering accessory" },
  ],
  kitOptions: ["Multiple color and texture options "],
  features: [
    "Ideal for concealing your AP in aesthetically sensitive environments ",
    "Custom designed to fit any Aruba 500 Series model AP ",
    "Toolless installation ",
    "Available in a variety of colors and textures ",
    "Tethering accessory included ",
  ],
  imgArr: [Cover1, Cover9],
  pathname: "/AMFG-Website-products-single/VC-AR500",
};

const APCover2 = {
  id: 2,
  partNum: "VC-C9130AXE-STA",
  dimensions: "24.038” x 18.716” x 2.853”",
  title: "Cisco 9130AXE-STA AP Vanity Shroud (Cover)",
  img: Cover4,
  pdf: Pdf3,
  description:
    "The VC-C9130AXE-STA fits seamlessly over the top of the Cisco C9130AXE-STA Access Point. This durable ABS plastic provides added protection against bumps and scratches and comes in a wide variety of colors to fit your specific needs. ",
  simplicity:
    "The VC-C9130AXE-STA is designed with recessed “catch” features on each corner of the cover for instant installation, no tools required. The cover features a peep hole that allows end users to see the status light without removing the cover. A tethering accessory is provided to comply with seismic requirements. ",
  technicalSpecs: ["Dimensions: 24.038” x 18.716” x 2.853”", "Weight: 1.5lbs"],
  kitIncludes: ["VC-C9130AXE-STA AP Vanity Cover ", "Tethering accessory "],
  kitIncludesData: [
    { img: Cover3, title: "VC-C9130AXE-STA AP Vanity Cover" },
    { img: ImgPlaceholder, title: "Tethering accessory" },
  ],
  kitOptions: ["Multiple color and texture options"],
  features: [
    "Ideal for concealing your AP in aesthetically sensitive environments ",
    "Custom designed to fit the Cisco C9130AXE-STA Access Point ",
    "Toolless installation ",
    "Available in a variety of colors and textures ",
    "Tethering accessory included ",
  ],
  imgArr: [Cover4, Cover11, Cover3, Cover5],
  pathname: "/AMFG-Website-products-single/VC-C9130AXE-STA",
};

const APCover3 = {
  id: 3,
  partNum: "VC-C9120AXI",
  dimensions: "8.76” x 8.76” x 1.715”",
  title: "Cisco 9120AXI AP Vanity Shroud (Cover)",
  img: Cover7,
  pdf: Pdf2,
  description:
    "The VC-C9120AXI fits seamlessly over the top of the Cisco 9120AXI Access Point. This durable ABS plastic provides added protection against bumps and scratches and comes in a wide variety of colors to fit your specific needs.",
  simplicity:
    "The VC-C9120AXI is designed with recessed “catch” features on each corner of the cover for instant installation, no tools required. The cover features a peep hole that allows end users to see the status light without removing the cover. A tethering accessory is provided to comply with seismic requirements. ",
  technicalSpecs: ["Dimensions: 8.76” x 8.76” x 1.715”", "Weight: 1lbs"],
  kitIncludes: ["VC-C9120AXI AP Vanity Cover", "Tethering accessory "],
  kitIncludesData: [
    { img: Cover7, title: "VC-C9120AXI AP Vanity Cover" },
    { img: ImgPlaceholder, title: "Tethering accessory" },
  ],
  kitOptions: ["Multiple color and texture options "],
  features: [
    "Ideal for concealing your AP in aesthetically sensitive environments ",
    "Custom designed to fit a Cisco C9120AXI model AP",
    "Toolless installation ",
    "Available in a variety of colors and textures ",
    "Tethering accessory included ",
  ],
  imgArr: [Cover7, Cover10],
  pathname: "/AMFG-Website-products-single/VC-C9120AXI",
};
const ApCovers = [APCover1, APCover3, APCover2];

export default ApCovers;
