import { useState } from "react";

import InteriorImg from "../../assets/imgs/mfg/enclosure-interior.png";
import plusIcon from "../../assets/imgs/icons/plus-icon.png";
import interiorData from "./InteriorData";

import InteractableModal from "./InteractableModal";

import "./new-interactable.css";

const InteriorInteractable = () => {
  const [showDescription, setShowDescription] = useState(false);
  const [data, setData] = useState();
  const dataArray = ["inner-left", "inner-middle", "inner-right"];

  const handleButtonClick = (key) => {
    setShowDescription(true);
    setData(interiorData.interiorData[key]);
  };

  return (
    <div className="interactable-img-container">
      <div
        style={{
          width: "fit-content",
          height: "fit-content",
          margin: "auto",
          position: "relative",
        }}
      >
        <img
          className="interactable-main-img"
          src={InteriorImg}
          alt="enclosure demo"
        />
        {dataArray.map((item, key) => {
          return (
            <div
              key={key}
              className={`${item} interactable-plus-icon `}
              onClick={() => handleButtonClick(key)}
            >
              <img src={plusIcon} alt="plus icon" />
            </div>
          );
        })}
      </div>
      {showDescription && (
        <InteractableModal
          data={data}
          setShowDescription={setShowDescription}
        />
      )}
    </div>
  );
};

export default InteriorInteractable;
